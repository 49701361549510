import { FeedsonCaseItem } from '@tecex-api/data';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export const doesTaskHaveMessages = ({ FeedsonTask }: { FeedsonTask?: FeedsonCaseItem[] }): boolean => {
  if (isNil(FeedsonTask)) {
    return false;
  }

  return FeedsonTask.some((item) => (!isNil(item.Body) && item.Body !== '') || !isEmpty(item.ContentDocId));
};
