import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { LoadingIndicatorComponent } from '../components/loading-indicator/loading-indicator.component';

@Injectable()
export class LoadingIndicatorService {
  private overlayRef: OverlayRef;
  // tslint:disable-next-line:variable-name
  private readonly numberOfOpenLoaders$ = new BehaviorSubject(0);

  constructor(private readonly overlay: Overlay) {
    this.isVisible$().subscribe((isVisible) => {
      if (isVisible) {
        this.overlayRef = this.overlay.create({
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
          // It's handled by BlockScrollService.
          scrollStrategy: this.overlay.scrollStrategies.noop(),
          hasBackdrop: true,
        });
        this.overlayRef.attach(new ComponentPortal(LoadingIndicatorComponent));
      } else if (this.overlayRef) {
        this.overlayRef.dispose();
        delete this.overlayRef;
      }
    });
  }

  public open(): void {
    this.numberOfOpenLoaders$.next(1);
  }

  public withFullscreen$<T>(source$: Observable<T>): Observable<T> {
    return new Observable((subscriber) => {
      this.open();
      source$.pipe(finalize(() => this.dispose())).subscribe(subscriber);
    });
  }

  public dispose(): void {
    this.numberOfOpenLoaders$.next(0);
  }

  public isVisible$(): Observable<boolean> {
    return this.numberOfOpenLoaders$.pipe(
      map((numberOfOpenLoaders) => numberOfOpenLoaders > 0),
      distinctUntilChanged()
    );
  }
}
