import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuoteDefaultValues } from '../../../../global/interfaces/quote-default-values.interface';
import { UserDefaultsService } from '../../../../global/services/user-defaults.service';
import { mapQuoteDefaultsResponse } from '../../common-profile/helpers/map-quote-defaults-response.helper';

@Injectable()
export class CommonChargeableWeightService {
  constructor(private readonly userDefaultsService: UserDefaultsService) {}

  public getQuoteDefaults$(): Observable<Partial<QuoteDefaultValues>> {
    return this.userDefaultsService.getUserDefaults$().pipe(map(mapQuoteDefaultsResponse));
  }
}
