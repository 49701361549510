<div class="dialog-container">
  <div class="dialog-content">
    <div class="d-flex justify-content-between mr-5">
      <h3 [ngSwitch]="step" class="dialog-title mb-0">
        <ng-container *ngSwitchCase="Step.ChooseFacilitator">{{ data.payload.title }}</ng-container>
        <ng-container *ngSwitchCase="Step.TecExConfirmation">{{ 'TASKS.PRE_INSPECTION.TECEX_TITLE' | translate }}</ng-container>
        <ng-container *ngSwitchCase="Step.ClientConfirmation">{{ 'TASKS.PRE_INSPECTION.CLIENT_TITLE' | translate }}</ng-container>
      </h3>
      <app-message-button
        class="message-button"
        [user]="data.payload.defaultTeamMember"
        (buttonClick)="onMessageClick()"
      ></app-message-button>
    </div>

    <app-shipment-summary
      *ngIf="data.payload.showShipmentOrderBox && data.payload.shipmentOrder"
      [shipmentOrder]="data.payload.shipmentOrder"
      class="mt-4 mb-4"
    ></app-shipment-summary>

    <div *ngIf="data.payload.isInactive" class="text-reduced-bold color-text-secondary py-3">
      {{ 'TASKS.INACTIVE_HINT' | translate }}
    </div>

    <div [ngSwitch]="step" class="text-reduced-regular color-text-secondary mb-4 mt-4">
      <ng-container *ngSwitchCase="Step.ChooseFacilitator">
        <div [innerHTML]="data.payload.description"></div>
        <app-selection
          [items]="[
            { value: PreInspectionFacilitator.Tecex, viewValue: 'TASKS.PRE_INSPECTION.TECEX_WILL_FACILITATE' | translate },
            { value: PreInspectionFacilitator.Client, viewValue: 'TASKS.PRE_INSPECTION.WE_WILL_FACILITATE' | translate }
          ]"
          [formControl]="preInscpectionFacilitatorControl"
          class="mt-4"
        ></app-selection>
      </ng-container>
      <div *ngSwitchCase="Step.TecExConfirmation" [innerHTML]="'TASKS.PRE_INSPECTION.TECEX_DESCRIPTION' | translate"></div>
      <ng-container *ngSwitchCase="Step.ClientConfirmation">
        <div [innerHTML]="'TASKS.PRE_INSPECTION.CLIENT_DESCRIPTION' | translate"></div>

        <div class="d-flex align-items-center mt-4">
          <mat-checkbox [formControl]="clientCompletedControl">
            <div class="text-tiny-regular color-text-secondary">{{ 'TASKS.PRE_INSPECTION.CLIENT_COMPLETED' | translate }}</div>
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex align-items-center justify-content-end w-100">
      <button mat-button type="button" class="secondary-button mr-3" (click)="onCancelClick()">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <div
        class="d-inline-block"
        [appTooltip]="data.payload.isInactive && 'TASKS.INACTIVE_TOOLTIP' | translate"
        [appTooltipStrategy]="'hover'"
      >
        <button
          *ngIf="step === Step.ChooseFacilitator; else submitButton"
          mat-button
          class="primary-button"
          (click)="onNextClick()"
          [disabled]="data.payload.isInactive || formGroup.invalid"
        >
          {{ 'COMMON.NEXT' | translate }}
        </button>
        <ng-template #submitButton>
          <button mat-button class="primary-button" (click)="onSubmitClick()" [disabled]="data.payload.isInactive || formGroup.invalid">
            {{ 'COMMON.SUBMIT' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
