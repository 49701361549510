import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GlobalModule } from '../../global.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { DropdownComponent } from './dropdown.component';

@NgModule({
  imports: [GlobalModule, ReactiveFormsModule, SvgIconsModule, MatAutocompleteModule, DropdownComponent],
  exports: [DropdownComponent],
})
export class DropdownModule {}
