import { CaseDetails as CaseDetailsResponse, UsersDetailsItem } from '@tecex-api/data';
import { MessageThreadType } from '../../enums/message-thread-type.enum';
import { MessageThreadDetails } from '../../interfaces/messages/message-thread-details.interface';
import { mapMessages } from './map-messages.helper';

export const mapCaseDetails = (
  { caseDetails, users }: { caseDetails: CaseDetailsResponse; users: UsersDetailsItem[] },
  userId: string,
  profilePictureFallback: string
): MessageThreadDetails => ({
  id: caseDetails.CaseDetails.Id,
  type: MessageThreadType.Case,
  subject: caseDetails.CaseDetails.subject,
  photoUrl: caseDetails.CaseDetails.FullphotoURL || profilePictureFallback,
  messages: mapMessages(caseDetails.Feedsoncase, users, userId),
  participants:
    caseDetails.Participants?.map((participant) => ({
      id: participant.ID,
      firstName: participant.Name,
      lastName: participant.LastName,
    })) || [],
  shipment: caseDetails.CaseDetails.NCPQuoteReference
    ? {
        id: caseDetails.CaseDetails.shipmentorderID,
        title: caseDetails.CaseDetails.NCPQuoteReference,
        reference: caseDetails.CaseDetails.ClientReference,
      }
    : undefined,
  recordType: caseDetails.CaseDetails.RecordTypename,
});
