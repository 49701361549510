<table class="table">
  <thead>
    <tr>
      <th rowspan="2" class="darker">{{ 'TASKS.BATTERY_TABLE.PACKAGE_GROUP_NUMBER' | translate }}</th>
      <th rowspan="2" class="darker">{{ 'TASKS.BATTERY_TABLE.PACKAGE_WEIGHT' | translate }}</th>
      <th rowspan="2" class="darker">{{ 'TASKS.BATTERY_TABLE.CONTAINS_BATTERIES' | translate }}</th>
      <th colspan="4" class="darker">{{ 'TASKS.BATTERY_TABLE.PACKAGE_BATTERY_PI_NUMBERS' | translate }}</th>
    </tr>
    <tr>
      <th>{{ 'TASKS.BATTERY_TABLE.ION_PI966' | translate }}</th>
      <th>{{ 'TASKS.BATTERY_TABLE.ION_PI967' | translate }}</th>
      <th>{{ 'TASKS.BATTERY_TABLE.METAL_PI969' | translate }}</th>
      <th>{{ 'TASKS.BATTERY_TABLE.METAL_PI970' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of packages.controls; let i = index">
      <td>{{ i + 1 }}</td>
      <td>{{ getWeight(i) | number: '1.0-2' }}</td>
      <td>
        <mat-checkbox [formControl]="$any(item).controls.hasBatteries" (ngModelChange)="onHasBatteriesChange($event, i)"></mat-checkbox>
      </td>
      <td *ngFor="let batteryType of $any(item).controls.batteries.controls | keyvalue: originalOrder">
        <mat-checkbox [formControl]="$any(batteryType.value)"></mat-checkbox>
      </td>
    </tr>
  </tbody>
</table>
