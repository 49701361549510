import isNil from 'lodash/isNil';
import { AddressAutocompleteResult } from '../interfaces/address-autocomplete-result.interface';

export const mapGooglePlaceResult = (place: google.maps.places.PlaceResult): AddressAutocompleteResult => ({
  address: getAddress(place),
  city: getAddressPartNameByType(place, 'locality'),
  state: getAddressPartNameByType(place, 'administrative_area_level_1'),
  country: getAddressPartNameByType(place, 'country'),
  zip: getAddressPartNameByType(place, 'postal_code'),
});

const getAddress = (place: google.maps.places.PlaceResult): string | undefined => {
  const street = getAddressPartNameByType(place, 'route');
  const streetNumber = getAddressPartNameByType(place, 'street_number');
  return [street, streetNumber].filter((entry) => !isNil(entry)).join(' ');
};

const getAddressPartNameByType = (place: google.maps.places.PlaceResult, type: string): string | undefined => {
  const placeComponent = place.address_components.find((component) => component.types.includes(type));
  return isNil(placeComponent) ? undefined : placeComponent.long_name;
};
