import isEmpty from 'lodash/isEmpty';
import { InputDataVM } from '../interfaces/input-data.vm';

export const constructInputData = <T>(value: T): InputDataVM<T, T> => {
  if (isEmpty(value)) {
    return undefined;
  }

  return {
    value,
    viewValue: value,
  };
};
