import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '../../../../global/helpers/loadable-state-reducer.helper';
import { Loadable } from '../../../../global/interfaces/loadable.interface';
import * as CommonMessagesActions from '../actions/common-messages.actions';

export const messagesKey = 'commonMessages';

export interface State {
  shipmentOrderParticipant: Loadable<any>;
  accountParticipant: Loadable<any>;
  messageChannelCreation: Loadable<any>;
  updateMessageCase: Loadable<any>;
  deleteMessageCase: Loadable<any>;
  awsToken: Loadable<any>;
  channelUpdateNameSuccess: boolean;
  channelDeleteCaseSuccess: boolean;
  channelInfoDetail: Loadable<any>;
}

export const initialState: State = {
  shipmentOrderParticipant: {
    data: [],
    isLoading: false,
  },
  accountParticipant: {
    data: [],
    isLoading: false,
  },
  messageChannelCreation: {
    data: [],
    isLoading: false,
  },
  updateMessageCase: {
    data: {},
    isLoading: false,
  },
  deleteMessageCase: {
    data: {},
    isLoading: false,
  },
  awsToken: {
    data: {},
    isLoading: false,
  },
  channelUpdateNameSuccess: false,
  channelDeleteCaseSuccess: false,
  channelInfoDetail: {
    data: {},
    isLoading: false,
  },
};

export const reducer = createReducer<State>(
  initialState,

  // Reducer for shipment-order participants - Start
  on(
    CommonMessagesActions.loadShipmentOrderParticipants,
    (state): State => ({
      ...state,
      shipmentOrderParticipant: LoadableStateReducerHelper.startLoad(state.shipmentOrderParticipant),
    })
  ),

  on(
    CommonMessagesActions.loadShipmentOrderParticipantsSuccess,
    (state, { shipmentOrderParticipant }): State => ({
      ...state,
      shipmentOrderParticipant: LoadableStateReducerHelper.loadSuccess(shipmentOrderParticipant),
    })
  ),

  on(CommonMessagesActions.loadShipmentOrderParticipantsError, (state, { error }) => ({
    ...state,
    shipmentOrderParticipant: LoadableStateReducerHelper.loadError(state.shipmentOrderParticipant, error),
  })),
  // Reducer for shipment-order participants - End

  // Reducer for account participants - Start
  on(
    CommonMessagesActions.loadAccountParticipants,
    (state): State => ({
      ...state,
      accountParticipant: LoadableStateReducerHelper.startLoad(state.accountParticipant),
    })
  ),

  on(
    CommonMessagesActions.loadAccountParticipantsSuccess,
    (state, { accountParticipant }): State => ({
      ...state,
      accountParticipant: LoadableStateReducerHelper.loadSuccess(accountParticipant),
    })
  ),

  on(CommonMessagesActions.loadAccountParticipantsError, (state, { error }) => ({
    ...state,
    accountParticipant: LoadableStateReducerHelper.loadError(state.accountParticipant, error),
  })),
  // Reducer for account participants - End

  // Reducer for create message channel creation - Start
  on(
    CommonMessagesActions.createMessageChannelCase,
    (state): State => ({
      ...state,
      messageChannelCreation: LoadableStateReducerHelper.startLoad(state.messageChannelCreation),
    })
  ),

  on(
    CommonMessagesActions.createMessageChannelCaseSuccess,
    (state, { messageChannelCreation }): State => ({
      ...state,
      messageChannelCreation: LoadableStateReducerHelper.loadSuccess(messageChannelCreation),
    })
  ),

  on(CommonMessagesActions.createMessageChannelCaseError, (state, { error }) => ({
    ...state,
    messageChannelCreation: LoadableStateReducerHelper.loadError(state.messageChannelCreation, error),
  })),
  // Reducer for create message channel creation - End

  // Reducer for update message case - Start
  on(
    CommonMessagesActions.updateMessageCases,
    (state): State => ({
      ...state,
      updateMessageCase: LoadableStateReducerHelper.startLoad(state.updateMessageCase),
    })
  ),

  on(
    CommonMessagesActions.updateMessageCaseSuccess,
    (state, { updateMessageCase }): State => ({
      ...state,
      updateMessageCase: LoadableStateReducerHelper.loadSuccess(updateMessageCase),
    })
  ),

  on(CommonMessagesActions.updateMessageCaseError, (state, { error }) => ({
    ...state,
    updateMessageCase: LoadableStateReducerHelper.loadError(state.updateMessageCase, error),
  })),
  // Reducer for update message case - End

  // Reducer for delete message case - Start
  on(
    CommonMessagesActions.deleteMessageCases,
    (state): State => ({
      ...state,
      deleteMessageCase: LoadableStateReducerHelper.startLoad(state.deleteMessageCase),
    })
  ),

  on(
    CommonMessagesActions.deleteMessageCasesSuccess,
    (state, { deleteMessageCase }): State => ({
      ...state,
      deleteMessageCase: LoadableStateReducerHelper.loadSuccess(deleteMessageCase),
    })
  ),

  on(CommonMessagesActions.deleteMessageCasesError, (state, { error }) => ({
    ...state,
    deleteMessageCase: LoadableStateReducerHelper.loadError(state.deleteMessageCase, error),
  })),
  // Reducer for delete message case - End

  on(
    CommonMessagesActions.loadAwstokens,
    (state): State => ({
      ...state,
      awsToken: LoadableStateReducerHelper.startLoad(state.awsToken),
    })
  ),
  on(
    CommonMessagesActions.loadAwstokensSuccess,
    (state, { token }): State => ({
      ...state,
      awsToken: LoadableStateReducerHelper.loadSuccess(token),
    })
  ),
  on(CommonMessagesActions.loadAwstokensError, (state, { error }) => ({
    ...state,
    awsToken: LoadableStateReducerHelper.loadError(state.awsToken, error),
  })),

  on(
    CommonMessagesActions.updateChannelNameSuccess,
    (state): State => ({
      ...state,
      channelUpdateNameSuccess: true,
    })
  ),

  // For channel info getting for updated list of participant - Start
  on(
    CommonMessagesActions.channelInfoForParticipantList,
    (state): State => ({
      ...state,
      channelInfoDetail: LoadableStateReducerHelper.startLoad(state.channelInfoDetail),
    })
  ),
  on(
    CommonMessagesActions.channelInfoForParticipantListSuccess,
    (state, { updatedParticipantList }): State => ({
      ...state,
      channelInfoDetail: LoadableStateReducerHelper.loadSuccess(updatedParticipantList),
    })
  ),
  on(CommonMessagesActions.channelInfoForParticipantListError, (state, { error }) => ({
    ...state,
    channelInfoDetail: LoadableStateReducerHelper.loadError(state.channelInfoDetail, error),
  })),
  // For channel info getting for updated list of participant - End

  // For remove the store detail from new-message click - Start
  on(
    CommonMessagesActions.channelInfoListEmpty,
    (state): State => ({
      ...state,
      channelInfoDetail: LoadableStateReducerHelper.loadSuccess(null),
    })
  ),
  // For remove the store detail from new-message click - End

  // For AWS delete channel API call after the success response - Start
  on(
    CommonMessagesActions.deleteChannelCaseSuccess,
    (state): State => ({
      ...state,
      channelDeleteCaseSuccess: true,
    })
  ),
  // For AWS delete channel API call after the success response - End

  on(CommonMessagesActions.leave, (): State => initialState)
);
