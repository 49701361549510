import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { DialogAppNavDrawerContainerComponent } from './components/dialog-app-nav-drawer-container/dialog-app-nav-drawer-container.component';
import { DialogDefaultContainerComponent } from './components/dialog-default-container/dialog-default-container.component';
import { DialogOverlayComponent } from './components/dialog-overlay/dialog-overlay.component';
import { DialogSideContainerComponent } from './components/dialog-side-container/dialog-side-container.component';
import { DialogService } from './dialog.service';
import { DialogCloseDirective } from './directives/dialog-close.directive';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    SvgIconsModule,
    PortalModule,
    MatButtonModule,
    DialogAppNavDrawerContainerComponent,
    DialogOverlayComponent,
    DialogDefaultContainerComponent,
    DialogSideContainerComponent,
    DialogCloseDirective,
  ],
  exports: [DialogCloseDirective],
})
export class DialogModule {
  public static forRoot(): ModuleWithProviders<DialogModule> {
    return { ngModule: DialogModule, providers: [DialogService] };
  }
}
