import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { LengthUnit } from '../enums/length-unit.enum';

@Pipe({
  name: 'lengthUnit',
  pure: true,
  standalone: true,
})
export class LengthUnitPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  // eslint-disable-next-line rxjs/finnish
  public transform(value: LengthUnit): Observable<string> {
    switch (value) {
      case LengthUnit.Cm: {
        return this.translateService.get('COMMON.CM');
      }
      case LengthUnit.Inch: {
        return this.translateService.get('COMMON.INCH');
      }
      default: {
        return of('Unknown unit');
      }
    }
  }
}
