import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';

import { MessageButtonComponent } from './message-button.component';

@NgModule({
  imports: [GlobalModule, TranslateModule, LoadingIndicatorModule, MessageButtonComponent],
  exports: [MessageButtonComponent],
})
export class MessageButtonModule {}
