<div class="dialog-container">
  <div class="dialog-content">
    <div class="dialog-title">
      {{ 'SESSION_EXPIRED_DIALOG.DESCRIPTION' | translate }}
    </div>
  </div>

  <div class="dialog-footer d-flex justify-content-center p-4">
    <div class="cross"></div>
    <div class="d-flex justify-content-center align-items-center button-container">
      <button mat-button appDialogClose class="primary-button">{{ 'SESSION_EXPIRED_DIALOG.BUTTON_LABEL' | translate }}</button>
    </div>
  </div>
</div>
