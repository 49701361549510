export enum LineItemsColumn {
  Id = 'id',
  ProductCode = 'Product code',
  Description = 'Description',
  Quantity = 'Quantity',
  UnitPrice = 'Unit price',
  HsCode = 'HS code',
  CountryOfOrigin = 'Country of Origin',
  StoreFees = 'Store fees',
}
