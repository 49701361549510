import { WeightUnit as WeightUnitResponse, WeightUnitWithNull } from '@tecex-api/data';
import { WeightUnit } from '../enums/weight-unit.enum';

export const mapWeightUnitWithNull = (weightUnit: WeightUnitWithNull): WeightUnit => {
  switch (weightUnit) {
    case WeightUnitWithNull.KGS: {
      return WeightUnit.Kg;
    }
    case WeightUnitWithNull.LBS: {
      return WeightUnit.Lbs;
    }
    default: {
      return WeightUnit.Kg;
    }
  }
};

export const mapWeightUnit = (weightUnit: WeightUnitResponse): WeightUnit => {
  switch (weightUnit) {
    case WeightUnitResponse.KGS: {
      return WeightUnit.Kg;
    }
    case WeightUnitResponse.LBS: {
      return WeightUnit.Lbs;
    }
    default: {
      return WeightUnit.Kg;
    }
  }
};
