import { ModuleWithProviders, NgModule } from '@angular/core';
import { LineItemsConfig } from './interfaces/line-items-config.interfaces';
import { LINE_ITEMS_DATA_SERVICE } from './tokens/line-items-data-service.token';
import { LINE_ITEMS_DYNAMIC_COLUMNS } from './tokens/line-items-dynamic-columns.token';
import { LINE_ITEMS_DYNAMIC_CURRENCY_COLUMNS } from './tokens/line-items-dynamic-currency-columns.token';
import { LINE_ITEMS_SUPPORTED_COLUMNS } from './tokens/line-items-supported-columns.token';

@NgModule({})
export class LineItemsConfigModule {
  public static forRoot(config: LineItemsConfig): ModuleWithProviders<LineItemsConfigModule> {
    return {
      ngModule: LineItemsConfigModule,
      providers: [
        { provide: LINE_ITEMS_DATA_SERVICE, useFactory: config.lineItemsDataServiceFactory },
        { provide: LINE_ITEMS_SUPPORTED_COLUMNS, useValue: config.supportedColumns },
        { provide: LINE_ITEMS_DYNAMIC_COLUMNS, useValue: config.dynamicColumns },
        { provide: LINE_ITEMS_DYNAMIC_CURRENCY_COLUMNS, useValue: config.dynamicCurrencyColumns },
      ],
    };
  }
}
