import { animate, animateChild, AnimationTriggerMetadata, group, query, state, style, transition, trigger } from '@angular/animations';

export const toggleAnimation = (height: number): AnimationTriggerMetadata =>
  trigger('toggleContent', [
    state('closed, void', style({ height: `${height}px`, minHeight: '0' })),
    state('open', style({ height: '*' })),
    transition('closed => open', [
      group([animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'), query('@toggleContentOpacity', [animateChild()])]),
    ]),
    transition('open => closed', [
      group([query('@toggleContentOpacity', [animateChild()]), animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
    ]),
  ]);

export const toggleOpacityAnimation: AnimationTriggerMetadata = trigger('toggleContentOpacity', [
  transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('100ms', style({ opacity: 0 }))]),
]);

export const optimizedToggleOpacityAnimation: AnimationTriggerMetadata = trigger('toggleContentOpacity', [
  transition('closed => open', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
  transition('open => closed', [style({ opacity: 1 }), animate('100ms', style({ opacity: 0 }))]),
]);
