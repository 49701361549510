import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ComponentRef, EmbeddedViewRef, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DIALOG_DATA } from '../../dialog.tokens';
import { DialogData } from '../../interfaces/dialog-data.interface';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class DialogBaseContainerComponent extends BasePortalOutlet implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: true }) private readonly portalOutlet: CdkPortalOutlet;

  @HostBinding('@state') public get hostAnimation(): string {
    return this.animationState;
  }

  @HostBinding('style.width') public get hostWidth(): string {
    return this.data.config.width;
  }

  private readonly destroy$ = new Subject<void>();
  private animationState = 'visible';

  constructor(@Inject(DIALOG_DATA) public readonly data: DialogData) {
    super();
  }

  public ngOnInit(): void {
    this.data.dialogRef
      .visible$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((visible) => {
        this.animationState = visible ? 'visible' : 'void';
      });
  }

  public attachComponentPortal<C>(portal: ComponentPortal<C>): ComponentRef<C> {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  public attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
    return this.portalOutlet.attachTemplatePortal(portal);
  }
}
