import { Inject, Injectable } from '@angular/core';
import insp, { install } from 'inspectlet-es';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class InspectletService {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    private readonly authService: AuthService
  ) {
    if (!this.config.inspectletId) {
      return;
    }

    install(Number(this.config.inspectletId));

    this.authService.getUser$().subscribe((user) => {
      insp(['identify', user.email]);

      insp([
        'tagSession',
        {
          Username: user.name,
          Email: user.email,
          'Account Name': user.accountName,
        },
      ]);
    });
  }
}
