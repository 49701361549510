<div class="col-3 pr-0">
  <div class="text-tiny-regular detail-label mb-3">
    {{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE_GROUP' | translate: { number: packageNumber } }}
  </div>
  <div class="count mb-2">{{ package.packageCount }}x</div>
</div>
<div class="col-3 pr-0">
  <div class="text-tiny-regular detail-label mb-1">{{ package.weight }} {{ weightUnit | weightUnit | async }}</div>
  <div class="text-tiny-regular detail-label">
    {{ package.length }} × {{ package.breadth }} × {{ package.height }}
    {{ lengthUnit | lengthUnit | async | lowercase }}
  </div>
</div>
<div class="col-4">
  <div class="text-tiny-regular detail-label">
    <strong
      >{{ (package.totalWeight === 0 ? getTotalWeight(package) : package.totalWeight) | number: '1.0-2' }}
      {{ 'COMMON.KG' | translate }}</strong
    >
    {{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.TOTAL_WEIGHT' | translate }}
  </div>
</div>
<div *ngIf="isEditable" class="action-container col-2 d-flex align-items-center justify-content-center">
  <button mat-icon-button type="button" class="color-secondary" (click)="onEdit()">
    <svg-icon key="pen"></svg-icon>
  </button>
</div>
<div *ngIf="!disableDelete" class="action-container col-2 d-flex align-items-center justify-content-center">
  <button mat-icon-button type="button" class="color-error" (click)="onDelete()">
    <svg-icon key="bin"></svg-icon>
  </button>
</div>
