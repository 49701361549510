import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

let uniqueId = 0;

@Injectable()
export class FormControlService {
  public id = uniqueId++;
  private readonly _hasError$ = new BehaviorSubject<boolean>(false);
  public set hasError(hasError: boolean) {
    this._hasError$.next(hasError);
  }
  public get hasError(): boolean {
    return this._hasError$.getValue();
  }
  public hasError$ = this._hasError$.asObservable();
}
