import { Pipe, PipeTransform } from '@angular/core';
import { PickupPreference } from '@tecex-api/data';
import { assertNever } from '../../../helpers/assert-never.helper';

@Pipe({
  name: 'pickupPreferenceTranslationKey',
  standalone: true,
})
export class PickupPreferenceTranslationKeyPipe implements PipeTransform {
  public transform(value: PickupPreference): string {
    switch (value) {
      case PickupPreference.TECEX_ARRANGES_PICKUP: {
        return 'ADDRESS.PICKUP_PREFERENCE_OPTIONS.TECEX_ARRANGE_PICKUP';
      }
      case PickupPreference.CLIENT_ARRANGES_PICKUP: {
        return 'ADDRESS.PICKUP_PREFERENCE_OPTIONS.CLIENT_ARRANGE_PICKUP';
      }
      case PickupPreference.DEDICATED_PICKUP___FED_EX: {
        return 'ADDRESS.PICKUP_PREFERENCE_OPTIONS.DEDICATED_PICKUP_FEDEX';
      }
      case PickupPreference.DEDICATED_PICKUP___DHL: {
        return 'ADDRESS.PICKUP_PREFERENCE_OPTIONS.DEDICATED_PICKUP_DHL';
      }
      default: {
        return assertNever(value, true);
      }
    }
  }
}
