<div class="subject-container" [class.editable]="editable">
  <div *ngIf="editable; else staticSubject" class="editable-subject-container w-100">
    <app-form-control [error]="subjectError$ | async">
      <input
        appFormControlInput
        type="text"
        class="form-control-input text-increased-regular subject-input w-100"
        [placeholder]="'MESSAGES.SUBJECT_PLACEHOLDER' | translate"
        [formControl]="subjectControl"
        data-hj-allow
      />
    </app-form-control>
    <div class="mt-2">
      <ng-container [ngTemplateOutlet]="shipmentLink"></ng-container>
    </div>
  </div>
  <ng-template #staticSubject>
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="static-subject-wrapper">
        <div class="static-subject text-increased-regular" [title]="subject">{{ subject }}</div>
      </div>
      <ng-container [ngTemplateOutlet]="shipmentLink"></ng-container>
    </div>
  </ng-template>
</div>

<ng-template #shipmentLink>
  <a *ngIf="shipment" [routerLink]="shipmentRouterLink" class="text-reduced-bold shipment-title">
    {{ shipment.title }}
    {{ shipment.reference }}
  </a>
</ng-template>
