<div class="packages">
  <ng-container *ngIf="packages$ | async as packages">
    <div
      *ngFor="let package of packages; let i = index"
      class="package"
      [@toggleContent]="isPackageOpen(i) ? 'open' : 'closed'"
      (click)="onClosedCardClick(i)"
    >
      <!-- CLOSED PACKAGE VIEW -->
      <div *ngIf="!isPackageOpen(i)" class="package-content closed d-flex align-items-center justify-content-between px-0 py-3">
        <app-package-closed-content-card
          [packageNumber]="i + 1"
          [package]="getClosedPackageData(i)"
          [weightUnit]="getClosedPackageData(i).weightUnit"
          [lengthUnit]="getClosedPackageData(i).lengthUnit"
          (delete)="onDeleteClick(i)"
        ></app-package-closed-content-card>
      </div>

      <!-- OPEN PACKAGE VIEW -->
      <div @toggleContentOpacity *ngIf="isPackageOpen(i)" [formGroup]="package.formGroup" class="package-content d-flex flex-column">
        <button *ngIf="packages.length > 1" mat-icon-button type="button" class="delete-button" (click)="onDeleteClick(i)">
          <svg-icon key="bin"></svg-icon>
        </button>
        <div class="group-name">
          {{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE_GROUP' | translate: { number: i + 1 } }}
        </div>
        <!-- WEIGHTS -->
        <div class="form-control weight-row">
          <app-form-control class="flex-grow-1" [error]="package.weightErrors$ | async">
            <span class="form-label" appFormControlLabel>{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.WEIGHT' | translate }}</span>
            <svg-icon key="weight" class="input-icon" appFormControlIcon></svg-icon>
            <input
              formControlName="weight"
              type="number"
              class="form-control-input"
              appFormControlInput
              appPositiveNumbersOnlyInput
              data-hj-allow
            />
            <span appFormControlHint>{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.WEIGHT_HINT' | translate }}</span>
          </app-form-control>
          <app-form-control class="select-metric align-self-center">
            <app-selection
              formControlName="weightUnit"
              [items]="[
                { value: WeightUnit.Kg, viewValue: 'COMMON.KG' | translate },
                { value: WeightUnit.Lbs, viewValue: 'COMMON.LBS' | translate }
              ]"
              appFormControlInput
            ></app-selection>
          </app-form-control>
        </div>

        <!-- PACKAGE SIZES -->
        <div class="form-control size-row">
          <app-form-control class="flex-grow-1" [error]="package.lengthErrors$ | async">
            <span class="form-label" appFormControlLabel>{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.SIZE' | translate }}</span>
            <svg-icon key="package-size" class="input-icon" appFormControlIcon></svg-icon>
            <input
              formControlName="length"
              type="number"
              class="form-control-input"
              appFormControlInput
              appPositiveNumbersOnlyInput
              data-hj-allow
            />
            <span appFormControlHint>{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.LENGTH' | translate }}</span>
          </app-form-control>
          <app-form-control class="flex-grow-1" [error]="package.breadthErrors$ | async">
            <svg-icon key="package-size" class="input-icon d-md-none" appFormControlIcon></svg-icon>
            <input
              formControlName="breadth"
              type="number"
              class="form-control-input"
              appFormControlInput
              appPositiveNumbersOnlyInput
              data-hj-allow
            />
            <span appFormControlHint>{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.BREADTH' | translate }}</span>
          </app-form-control>
          <app-form-control class="flex-grow-1" [error]="package.heightErrors$ | async">
            <svg-icon key="package-size" class="input-icon d-md-none" appFormControlIcon></svg-icon>
            <input
              formControlName="height"
              type="number"
              class="form-control-input"
              appFormControlInput
              appPositiveNumbersOnlyInput
              data-hj-allow
            />
            <span appFormControlHint>{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.HEIGHT' | translate }}</span>
          </app-form-control>

          <app-form-control class="select-metric align-self-center mt-4 mt-md-0">
            <app-selection
              formControlName="lengthUnit"
              [items]="[
                { value: LengthUnit.Cm, viewValue: 'COMMON.CM' | translate },
                { value: LengthUnit.Inch, viewValue: 'COMMON.INCH' | translate }
              ]"
              appFormControlInput
            ></app-selection>
          </app-form-control>
        </div>

        <!-- COUNT -->
        <div class="d-flex flex-grow-1 form-control">
          <app-form-control class="flex-grow-1" [error]="package.packageCountErrors$ | async">
            <span class="form-label" appFormControlLabel>{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.HOW_MANY_PACKAGES' | translate }}</span>
            <svg-icon key="packages-number" class="input-icon" appFormControlIcon></svg-icon>
            <input
              formControlName="packageCount"
              type="number"
              class="form-control-input"
              appFormControlInput
              appPositiveNumbersOnlyInput
              data-hj-allow
            />
            <span appFormControlHint>{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.NUMBER_OF_PACKAGES' | translate }}</span>
          </app-form-control>
        </div>

        <ng-container [ngSwitch]="packageContentType">
          <ng-container *ngSwitchCase="PackageContentType.Batteries">
            <!-- BATTERIES -->
            <div class="form-control d-flex align-items-center">
              <mat-checkbox formControlName="hasBatteries">
                <span class="battery-text text-tiny-regular">{{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.HAVE_BATTERIES' | translate }}</span>
              </mat-checkbox>
              <svg-icon
                key="info"
                class="ml-1 icon-info"
                [appTooltip]="'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.BATTERY_TOOLTIP' | translate"
              ></svg-icon>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="PackageContentType.Dangerous">
            <!-- DANGEROUS GOODS -->
            <div class="form-control d-flex align-items-center">
              <mat-checkbox formControlName="hasDangerousGoods">
                <span class="battery-text text-tiny-regular">{{
                  'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.HAVE_DANGEROUS_GOODS' | translate
                }}</span>
              </mat-checkbox>
              <svg-icon
                key="info"
                class="ml-1 icon-info"
                [appTooltip]="'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.DANGEROUS_GOODS_TOOLTIP' | translate"
              ></svg-icon>
            </div>
          </ng-container>
        </ng-container>

        <!-- STACKABLE PACKAGES NOTE -->
        <div class="note-stackable text-tiny-regular">
          {{ 'CHARGEABLE_WEIGHTS_DIALOG.PACKAGE.STACKABLE_PACKQAGES' | translate }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
