<div class="dialog-container">
  <div class="dialog-content">
    <div class="dialog-title">{{ 'QUOTE_DETAILS.CANCEL_QUOTE.WHY_ARE_YOU_CANCELLING' | translate }}</div>
    <mat-radio-group class="radio-group" (change)="onRadioChange($event)" [value]="closingReason">
      <mat-radio-button *ngFor="let option of radioOptions" class="radio-item" [value]="option.value">
        <div class="text-small-regular color-text-secondary">{{ option.viewValue | translate }}</div>
      </mat-radio-button>
      <div *ngIf="shouldShowOtherReasonOption" class="other-reason">
        <mat-radio-button [value]="otherReasonTypeValue">
          <div class="d-inline-flex text-small-regular color-text-secondary">{{ 'COMMON.OTHER' | translate }}:</div>
          <input [(ngModel)]="otherReason" type="text" class="form-control-input ml-2" (focus)="onOtherFocus()" data-hj-allow />
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <div class="actions">
      <button mat-button type="button" class="primary-button" (click)="onCancelQuoteClick()" [disabled]="!closingReason">
        {{ 'QUOTE_DETAILS.CANCEL_QUOTE.CANCEL_QUOTE' | translate }}
      </button>
    </div>
  </div>
</div>
