import { poundsInKilogram } from '../../../constants/global.constants';
import { WeightUnit } from '../../../enums/weight-unit.enum';

export const mapEstimatedWeight = (weight: number, weightUnit: WeightUnit): number => {
  switch (weightUnit) {
    case WeightUnit.Kg: {
      return weight;
    }
    case WeightUnit.Lbs: {
      return weight / poundsInKilogram;
    }
    default: {
      return weight;
    }
  }
};
