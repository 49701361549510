import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isToday, isYesterday } from '../helpers/utils.helper';

@Pipe({
  name: 'messageDate',
  pure: true,
  standalone: true,
})
export class MessageDatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly translateService: TranslateService
  ) {}

  public transform(date: Date): string {
    if (isToday(date)) {
      return formatDate(date, 'h:mm a', this.locale);
    }

    if (isYesterday(date)) {
      return `${this.translateService.instant('COMMON.YESTERDAY')}, ${formatDate(date, 'h:mm a', this.locale)}`;
    }

    return formatDate(date, 'MMM d, y, h:mm a', this.locale);
  }
}
