import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { GlobalModule } from '../../global.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { MessageCardDescriptionDirective } from './directives/message-card-description.directive';
import { MessageCardIconDirective } from './directives/message-card-icon.directive';
import { MessageCardTitleDirective } from './directives/message-card-title.directive';
import { MessageCardComponent } from './message-card.component';

@NgModule({
  imports: [
    GlobalModule,
    SvgIconsModule,
    MatButtonModule,
    MessageCardComponent,
    MessageCardIconDirective,
    MessageCardTitleDirective,
    MessageCardDescriptionDirective,
  ],
  exports: [MessageCardComponent, MessageCardIconDirective, MessageCardTitleDirective, MessageCardDescriptionDirective],
})
export class MessageCardModule {}
