import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { AWSResponseStatusMock, LineItemsMockService } from './services/line-items-mock.service';

const NOT_MOCKED_RESPONSE = new HttpResponse({
  status: 500,
  body: '[NOT MOCKED] not mocked',
});
const MOCK_RESPONSE_DELAY_MS = 1000;

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  private readonly lineItemsMockService: LineItemsMockService = new LineItemsMockService();

  constructor(@Inject(CONFIG_TOKEN) private readonly config: GlobalConfig) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.config.apiMocking) {
      return next.handle(request);
    }

    if (!request.url.startsWith(this.config.apiBaseUrl)) {
      return next.handle(request);
    }

    const method = request.method;
    const path = request.url.slice(this.config.apiBaseUrl.length);

    let response: HttpResponse<any>;

    // default leave it as NOT_MOCKED
    response = NOT_MOCKED_RESPONSE;

    // line items
    if (method === 'POST' && path === '/NCPAddPartsDetail') {
      // eslint-disable-next-line no-console
      console.log('mocking /NCPAddPartsDetail');
      if (this.lineItemsMockService.mockNCPAddPartsDetailRetry === 3) {
        response = this.lineItemsMockService.mockNCPAddPartsDetail(AWSResponseStatusMock.Error);
      } else if (this.lineItemsMockService.mockNCPAddPartsDetailRetry === 1) {
        response = this.lineItemsMockService.mockNCPAddPartsDetail(AWSResponseStatusMock.Error);
      } else {
        response = this.lineItemsMockService.mockNCPAddPartsDetail(AWSResponseStatusMock.Pending);
      }
      this.lineItemsMockService.mockNCPAddPartsDetailRetry++;
    } else {
      return next.handle(request);
    }
    return of(response).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 200) {
          throw new HttpErrorResponse({ status: event.status });
        }
        return event;
      }),
      delay(MOCK_RESPONSE_DELAY_MS)
    );
  }
}
