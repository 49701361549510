import { SimplifiedQuoteAndShipmentStatus } from '@tecex-api/data';
import { ShipmentStatus } from '../enums/shipment-status.enum';

export const mapShipmentStatus = (status: SimplifiedQuoteAndShipmentStatus): ShipmentStatus | undefined => {
  switch (status) {
    case SimplifiedQuoteAndShipmentStatus.SHIPMENT___COMPLIANCE_PENDING: {
      return ShipmentStatus.CompliancePending;
    }
    case SimplifiedQuoteAndShipmentStatus.SHIPMENT___TRACKING: {
      return ShipmentStatus.Tracking;
    }
    case SimplifiedQuoteAndShipmentStatus.SHIPMENT___COMPLETED: {
      return ShipmentStatus.Completed;
    }
    default: {
      return undefined;
    }
  }
};
