<app-form-control *ngIf="payload.type === InputType.Textarea; else input" class="textarea-control">
  <span *ngIf="payload.label" appFormControlLabel>{{ payload.label }}</span>
  <textarea class="form-control-input" appFormControlInput appTextareaAutosize [rows]="1" [formControl]="formControl"></textarea>
  <span *ngIf="payload.hint" appFormControlHint>{{ payload.hint }}</span>
</app-form-control>

<ng-template #input>
  <app-form-control class="form-control" [ngSwitch]="payload.type">
    <svg-icon *ngIf="payload.type === InputType.Email" key="messages" appFormControlIcon></svg-icon>
    <span *ngIf="payload.label" appFormControlLabel>{{ payload.label }}</span>

    <input
      *ngSwitchCase="InputType.Numeric"
      appFormControlInput
      data-hj-allow
      type="number"
      class="form-control-input text-input-appearance"
      [formControl]="formControl"
    />
    <input *ngSwitchDefault appFormControlInput data-hj-allow type="text" class="form-control-input" [formControl]="formControl" />

    <span *ngIf="payload.hint" appFormControlHint>{{ payload.hint }}</span>
  </app-form-control>
</ng-template>
