import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPositiveNumbersOnlyInput]',
  standalone: true,
})
export class PositiveNumbersOnlyInputDirective {
  @Input() public type = 'text';

  private readonly numberTypeInputDisallowedCharacters = ['e', '+', '-'];

  constructor(private readonly ngControl: NgControl) {}

  @HostListener('input')
  public onInput(): void {
    if (this.type !== 'number') {
      const value = this.ngControl.control.value;
      this.ngControl.control.setValue(Number(this.sanitizeString(value)));
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeydown(e: KeyboardEvent): void {
    if (this.type === 'number' && this.numberTypeInputDisallowedCharacters.includes(e.key.toLowerCase())) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(e: ClipboardEvent): void {
    if (this.type === 'number') {
      e.preventDefault();
      const value = e.clipboardData.getData('text/plain');
      this.ngControl.control.setValue(Number(this.sanitizeString(value)));
    }
  }

  private readonly sanitizeString = (value: string): string => value.replaceAll(/[^\d,.]/g, '');
}
