import { Injectable, Injector } from '@angular/core';
import { DialogRef } from '../../../dialog/dialog-ref';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogShowMode } from '../../../dialog/enums/dialog-show-mode.enum';
import { PreInspectionFacilitator } from '../../enums/pre-inspection-facilitator.enum';
import { PreInspectionDialogPayload } from './pre-inspection-dialog-payload.interface';
import { PreInspectionDialogComponent } from './pre-inspection-dialog.component';

@Injectable()
export class PreInspectionDialogService {
  constructor(
    private readonly injector: Injector,
    private readonly dialogService: DialogService
  ) {}

  public open(payload: PreInspectionDialogPayload): DialogRef<PreInspectionFacilitator> {
    return this.dialogService.open<PreInspectionDialogPayload, PreInspectionFacilitator>(PreInspectionDialogComponent, payload, {
      showMode: DialogShowMode.Side,
      closeButton: false,
      closeOnNavigation: true,
      width: '800px',
      injector: this.injector,
    });
  }
}
