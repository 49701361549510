import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { SelectComponent } from './select.component';

@NgModule({
  imports: [CommonModule, MatSelectModule, SvgIconsModule, SelectComponent],
  exports: [SelectComponent],
})
export class SelectModule {}
