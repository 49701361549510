<div class="d-flex indicator-container" [class.has-title]="hasTitle">
  <div class="d-flex flex-column align-items-start indicator-circle" *ngFor="let task of shiptmentTasks; let i = index">
    <app-state-indicator-circle
      [color]="isCancelled ? StateIndicatorCircleState.Gray : task.color"
      [size]="dotSize"
      [appTooltip]="tooltip"
      [appTooltipColor]="$any(task.tooltipColor)"
    >
      <ng-template #tooltip>
        <div class="text-micro-bold">{{ task.title | translate }}</div>
        <div class="text-micro-regular">{{ task.status | translate }}</div>
      </ng-template>
    </app-state-indicator-circle>
    <span *ngIf="hasTitle" class="text-micro-regular color-text-primary pt-2">{{ task.title | translate }}</span>
  </div>
</div>
