import { ShipmentOrderRelationsShipmentOrderPackages, UpdateShipmentOrderPackagesSOP } from '@tecex-api/data';
import { BatteryType } from '../enums/battery-type.enum';
import { BatteryTableBlockPackage } from '../types/battery-table-block-package.interface';

export const mapUpdatePackagePayload = (
  result: BatteryTableBlockPackage,
  shipmentOrderPackage: ShipmentOrderRelationsShipmentOrderPackages
): UpdateShipmentOrderPackagesSOP => ({
  SOPID: result.id,
  Actual_Weight: shipmentOrderPackage.ActualWeight,
  Breadth: shipmentOrderPackage.Breadth,
  Height: shipmentOrderPackage.Height,
  Length: shipmentOrderPackage.Length,
  Packages_of_Same_Weight: shipmentOrderPackage.PackagesofSameWeightDimensions,
  Dimension_Unit: shipmentOrderPackage.DimensionUnit,
  Weight_Unit: shipmentOrderPackage.WeightUnit,
  LithiumBatteries: shipmentOrderPackage.LithiumBatteries,
  Contains_Batteries: result.hasBatteries,
  ION_PI966: result.batteries[BatteryType.ION_PI966],
  ION_PI967: result.batteries[BatteryType.ION_PI967],
  Metal_PI969: result.batteries[BatteryType.METAL_PI969],
  Metal_PI970: result.batteries[BatteryType.METAL_PI970],
});
