<div class="dialog-container">
  <div class="dialog-content">
    <!-- HEADER -->
    <div class="d-flex justify-content-between mr-5">
      <h3 class="dialog-title" [innerText]="task.title"></h3>
      <app-message-button
        class="d-none d-md-block message-button"
        [user]="defaultTeamMember"
        (buttonClick)="onMessageClick()"
      ></app-message-button>
    </div>

    <!-- SHIPMENT ORDER -->
    <div *ngIf="showShipmentOrderBox && task.shipmentOrder" class="mb-4">
      <ng-template #shipmentSummary></ng-template>
    </div>

    <!-- DESCRIPTION -->
    <div class="text-reduced-regular color-text-secondary" [innerHTML]="task.description"></div>

    <div *ngIf="task.isInactive" class="text-reduced-bold color-text-secondary py-3">
      {{ 'TASKS.INACTIVE_HINT' | translate }}
    </div>

    <div class="blocks-container">
      <div *ngIf="task.isInactive" class="blocks-overlay"></div>
      <app-task-blocks [blocks]="task.blocks" (validityChange)="onBlocksValidityChange($event)"></app-task-blocks>
    </div>
  </div>

  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex align-items-center p-4">
      <div *ngIf="task.messageThread" class="message-thread d-flex align-items-center" (click)="onMessageClick()">
        <img class="profile-picture" [src]="task.messageThread.participants[0]?.pictureUrl | profilePicture" />

        <div class="inner d-flex align-items-center">
          <div>
            <div class="subject text-reduced-bold color-text-primary">
              {{ task.messageThread.subject }}
            </div>
            <div *ngIf="task.messageThread.participants.length" class="text-tiny-regular color-text-tertiary">
              {{ task.messageThread.participants | messageThreadParticipants }}
            </div>
          </div>

          <button class="expand ml-3 text-reduced-bold d-flex align-items-center color-secondary">
            <svg-icon key="enlarge" class="mr-1 expand-icon"></svg-icon>
            {{ 'TASKS.EXPAND_THREAD' | translate }}
          </button>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end w-100">
        <button mat-button type="button" class="secondary-button mr-3" (click)="onCancelClick()">
          {{ 'COMMON.CANCEL' | translate }}
        </button>
        <div class="d-inline-block" [appTooltip]="task.isInactive && 'TASKS.INACTIVE_TOOLTIP' | translate" [appTooltipStrategy]="'hover'">
          <button mat-button type="button" class="primary-button" (click)="onSubmitClick()" [disabled]="!areBlocksValid || task.isInactive">
            {{ buttonLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
