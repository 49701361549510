<div class="d-flex">
  <ng-container *ngIf="size === 'small'; else detailedTracker">
    <div class="d-flex flex-column">
      <div class="text-micro-bold color-text-secondary">{{ title | translate }} {{ date | date: '[dd/MM]' }}</div>
      <div class="d-flex mt-2">
        <div
          *ngFor="let item of trackerSteps"
          class="small-tracker-item"
          [class.completed]="isStateCompleted(item.state) && state !== TrackingState.Cancelled"
          [class.cancelled]="state === TrackingState.Cancelled"
        ></div>
      </div>
    </div>
  </ng-container>

  <ng-template #detailedTracker>
    <div
      *ngFor="let item of trackerSteps"
      class="tracker-item"
      [class.completed]="isStateCompleted(item.state) && state !== TrackingState.Cancelled"
      [class.cancelled]="state === TrackingState.Cancelled"
    >
      <div class="text-micro-regular color-text-primary tracker-text">{{ item.title | translate }}</div>
    </div>
  </ng-template>
</div>
