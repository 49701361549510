<a
  [routerLink]="menuItem.link"
  [routerLinkActiveOptions]="{ exact: menuItem.exact }"
  class="link text-decoration-none flex-column"
  routerLinkActive="active"
>
  <div class="icon">
    <svg-icon [key]="menuItem.icon"></svg-icon>
  </div>
  <div class="text-micro-bold title">{{ menuItem.title | translate }}</div>
</a>
