import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { CancelQuoteDialogComponent } from './cancel-quote-dialog.component';
import { CancelQuoteDialogService } from './cancel-quote-dialog.service';
import { CancelQuoteDialogConfig } from './interfaces/cancel-quote-dialog-config.interface';
import { CANCEL_QUOTE_DIALOG_CONFIG } from './tokens/cancel-quote-dialog-config.token';

@NgModule({
  imports: [CommonModule, MatRadioModule, MatButtonModule, FormsModule, TranslateModule, CancelQuoteDialogComponent],
})
export class CancelQuoteDialogModule {
  public static forRoot(options: CancelQuoteDialogConfig): ModuleWithProviders<CancelQuoteDialogModule> {
    return {
      ngModule: CancelQuoteDialogModule,
      providers: [CancelQuoteDialogService, { provide: CANCEL_QUOTE_DIALOG_CONFIG, useValue: options }],
    };
  }
}
