import { Injectable } from '@angular/core';

import isNil from 'lodash/isNil';
import { AuthService } from '../services/auth.service';

/**
 * Is opposite to AuthGuard
 * It only allows navigation if current user has no credentials (meaning they're not logged in)
 */
@Injectable({
  providedIn: 'root',
})
export class UnAuthGuard {
  constructor(private readonly authService: AuthService) {}

  public canLoad(): boolean {
    return this.hasNoCredentials();
  }

  public canActivate(): boolean {
    return this.hasNoCredentials();
  }

  public canActivateChild(): boolean {
    return this.hasNoCredentials();
  }

  private hasNoCredentials(): boolean {
    const credentials = this.authService.getCredentials();
    return isNil(credentials);
  }
}
