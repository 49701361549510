<ng-container>
  <div class="pointing-arrow" [ngClass]="arrowClass"></div>
  <div class="content-container" [ngClass]="arrowClass">
    <button *ngIf="data.closeable" mat-icon-button type="button" class="close-button" (click)="onCloseClicked()">
      <svg-icon key="close"></svg-icon>
    </button>
    <div class="text-micro-regular">
      <ng-container *ngIf="isTemplate(data.tooltip); else html" [ngTemplateOutlet]="data.tooltip"></ng-container>
      <ng-template #html>
        <div [innerHTML]="data.tooltip"></div>
      </ng-template>
    </div>
  </div>
</ng-container>
