import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';

@Pipe({
  name: 'downloadDocumentUrl',
  standalone: true,
})
export class DownloadDocumentUrlPipe implements PipeTransform {
  constructor(@Inject(CONFIG_TOKEN) private readonly config: GlobalConfig) {}

  public transform(id: string): string {
    return `${this.config.documentDownloadBaseUrl}${this.config.documentDownloadPath.replace('{id}', id)}`;
  }
}
