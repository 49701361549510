import { HttpResponse } from '@angular/common/http';

export enum AWSResponseStatusMock {
  Pending = '800',
  Error = '400',
  Success = '200',
}
export class LineItemsMockService {
  public mockNCPAddPartsDetailRetry = 0;
  public mockNCPAddPartsDetail(success: AWSResponseStatusMock, status = 200): HttpResponse<any> {
    return new HttpResponse({
      status,
      body: {
        Message: {
          AWSResponseStatus: `${success} - success`,
        },
        Part: [],
      },
    });
  }
}
