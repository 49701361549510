<div class="dialog-container">
  <div class="dialog-content">
    <img [src]="imgPath" class="logo" alt="TecEx client portal" width="80" height="32" />

    <div class="text-center">
      <svg-icon key="exclamation-mark" class="icon"></svg-icon>
      <div class="text-increased-bold color-text-primary text-uppercase mt-4">{{ 'MAINTENANCE.DIALOG_TITLE' | translate }}</div>
      <p class="text-reduced-regular">
        {{ 'MAINTENANCE.DIALOG_MESSAGE' | translate }}
        <span *ngIf="announcment.type === AnnounctmentType.Maintenance" class="color-primary font-weight-medium"
          >{{
            'MAINTENANCE.DIALOG_FROM_TO'
              | translate: { from: announcment.startDate | date: 'short', to: announcment.endDate | date: 'short' }
          }}
        </span>
      </p>
    </div>

    <div class="text-tiny-regular mt-4 color-text-primary">
      {{ 'MAINTENANCE.DIALOG_SIGNATURE' | translate }}
      <div class="text-tiny-medium">{{ 'MAINTENANCE.DIALOG_SIGNATURE_TEAM' | translate }}</div>
    </div>
  </div>

  <div class="dialog-footer d-flex align-items-center justify-content-center py-3">
    <button mat-button class="secondary-button" (click)="onLogoutClick()">
      {{ 'COMMON.LOG_OUT' | translate }}
    </button>
    <button mat-button class="primary-button ml-4" (click)="onRefreshClick()">
      {{ 'MAINTENANCE.DIALOG_REFRESH_BUTTON_LABEL' | translate }}
    </button>
  </div>
</div>
