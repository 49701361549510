import { Observable, Subject } from 'rxjs';

export class ToastMessageRef {
  // tslint:disable-next-line: variable-name
  private readonly _afterClosed$ = new Subject<void>();
  // tslint:disable-next-line: variable-name
  private readonly _callToActionClicked$ = new Subject<void>();

  public afterClosed$(): Observable<void> {
    return this._afterClosed$.asObservable();
  }

  public close(): void {
    this._afterClosed$.next();
    this._afterClosed$.complete();
  }

  public callToActionClicked$(): Observable<void> {
    return this._callToActionClicked$.asObservable();
  }

  public emitCallToAction(): void {
    this._callToActionClicked$.next();
  }
}
