import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isToday, isYesterday } from '../helpers/utils.helper';

@Pipe({
  name: 'sendbirdMessageDate',
  pure: true,
  standalone: true,
})
export class SendbirdMessageDatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly translateService: TranslateService
  ) {}

  public transform(date: number): string {
    // NOTE : Same code as the case-date pipe and other commented code is working as well.

    if (!date) {
      return undefined;
    }

    const dateObj = new Date(date);

    if (isToday(dateObj)) {
      return formatDate(dateObj, 'h:mm a', this.locale);
    }

    if (isYesterday(dateObj)) {
      return `${this.translateService.instant('COMMON.YESTERDAY')}, ${formatDate(dateObj, 'h:mm a', this.locale)}`;
    }

    return formatDate(dateObj, 'MMM d y, h:mm a', this.locale);
  }
}
