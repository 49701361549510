import { Breakpoints as MatBreakpoints } from '@angular/cdk/layout';

/** Extended list of app breakpoints */
export const BREAKPOINTS = {
  ...MatBreakpoints,
  LargeDown: '(max-width: 1919.98px)',
  LargeUp: '(min-width: 1280px)',
  MediumDown: '(max-width: 1279.98px)',
  MediumUp: '(min-width: 960px)',
  SmallDown: '(max-width: 959.98px)',
  SmallUp: '(min-width: 600px)',
} as const;
