import { Injectable } from '@angular/core';
import { ShippingStatus } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { UpdateQuoteService } from '../common-quote/services/update-quote.service';
import { DialogService } from '../dialog/dialog.service';
import { CancelQuoteDialogComponent } from './cancel-quote-dialog.component';
import { CancelQuoteDialogResult } from './interfaces/cancel-quote-dialog-result.interface';

@Injectable()
export class CancelQuoteDialogService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly updateQuoteService: UpdateQuoteService
  ) {}

  public cancelQuote$(id: string): Observable<void> {
    return this.dialogService
      .open<undefined, CancelQuoteDialogResult>(CancelQuoteDialogComponent)
      .afterClosed$()
      .pipe(
        filter((result) => !isNil(result)),
        switchMap((result) =>
          this.updateQuoteService.updateQuote$(id, {
            status: ShippingStatus.COST_ESTIMATE_REJECTED,
            cancelQuoteReason: result.reasonType,
            reasonForCancel: result.reason,
          })
        )
      );
  }
}
