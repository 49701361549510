import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  template: '<div [innerHtml]="content"></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ToastMessageWrapperComponent {
  @Input() public content: string;
}
