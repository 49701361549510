import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LoadingIndicatorService } from '@global/modules/loading-indicator/services/loading-indicator.service';
import { AuthService } from '@global/services/auth.service';
import {
  acceptQuoteRetryInterface,
  addEditLineItemsRetryInterface,
  deleteSoPackageRetryInterface,
  generateCostEstimateRetryInterface,
  getCourierRatesRetryInterface,
  ReMessageArrayInterface,
  SoRelatedObjectsInterface,
  updateCourierRatesRetryInterface,
  updateFinalDeliveryAddressesRetryInterface,
  updateFreightAddressRetryInterface,
  updateShippingFeesRetryInterface,
  updateSoPackageRetryInterface,
  updateTaskRetryInterface,
} from '@shared/interfaces/re-api-retry.interface';
import { SoDetailsPollInterface } from '@shared/interfaces/so-details-poll.interface';
import {
  CourierRate,
  QuoteDataService,
  ShipmentOrder,
  ShipmentOrderDataService,
  ShipmentOrderPackage,
  ShipmentOrderPackageDataService,
  ShipmentOrderRelations,
  Success,
  TasksDataService,
  ZeeQuoteDataService,
} from '@tecex-api/data';
import { AddPartsResponseItem } from '@tecex-api/data/model/addPartsResponseItem';
import { combineLatest, Observable, of, retry } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

const DEFAULT_POLL_INTERVAL = 2500;
const DEFAULT_POLL_COUNT = 25;

@Injectable({
  providedIn: 'root',
})
export class RePollService {
  constructor(
    private readonly authService: AuthService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly quoteDataService: QuoteDataService,
    private readonly shipmentOrderDataService: ShipmentOrderDataService,
    private readonly shipmentOrderPackageDataService: ShipmentOrderPackageDataService,
    private readonly zeeQuoteDataService: ZeeQuoteDataService,
    private readonly router: Router,
    private readonly tasksDataService: TasksDataService
  ) {}

  private readonly routerChange$ = this.router.events.pipe(
    filter((event) => {
      return event instanceof NavigationStart && !this.router.url.includes('#task');
    })
  );
  public pollSoDetails$(SoDetailsPollRequest: SoDetailsPollInterface): Observable<ShipmentOrder> {
    return this.shipmentOrderDataService
      .getShipmentOrder({
        Accesstoken: SoDetailsPollRequest.accessToken,
        AccountID: SoDetailsPollRequest.accountId,
        ContactID: SoDetailsPollRequest.contactId,
        SOID: SoDetailsPollRequest.shipmentOrderId,
      })
      .pipe(
        map((resp) => {
          if (!resp?.RE_TriggerEvent__c || resp?.RE_CriticalError__c) {
            return resp;
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: SoDetailsPollRequest.pollCount ? SoDetailsPollRequest.pollCount : DEFAULT_POLL_COUNT,
          delay: SoDetailsPollRequest.pollInterval ? SoDetailsPollRequest.pollInterval : DEFAULT_POLL_INTERVAL,
        }),
        takeUntil(this.routerChange$)
      );
  }

  public pollDetailAndRelatedObjects$(SoDetailsPollRequest: SoDetailsPollInterface): Observable<[ShipmentOrder, ShipmentOrderRelations]> {
    return this.pollSoDetails$(SoDetailsPollRequest).pipe(
      mergeMap((resp) => {
        return combineLatest([
          of(resp),
          this.shipmentOrderDataService.getShipmentOrderRelations({
            Accesstoken: SoDetailsPollRequest.accessToken,
            AccountID: SoDetailsPollRequest.accountId,
            SOID: SoDetailsPollRequest.shipmentOrderId,
          }),
        ]);
      }),
      map(([resp, resp2]) => {
        return [resp, resp2];
      })
    );
  }

  public createSoOrderPackageRetry$(createPackageRequest: updateSoPackageRetryInterface): Observable<Array<ShipmentOrderPackage>> {
    return this.shipmentOrderPackageDataService
      .createShipmentOrderPackages({
        Accesstoken: createPackageRequest.user.accessToken,
        AccountID: createPackageRequest.user.accountId,
        ShipmentOrder_Packages: createPackageRequest.payload,
      })
      .pipe(
        map((resp: ReMessageArrayInterface | ShipmentOrderPackage[]) => {
          if (Array.isArray(resp)) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: createPackageRequest.retryCount ? createPackageRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: createPackageRequest.retryInterval ? createPackageRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public updateSoPackageRetry$(updateSoPackageRetryRequest: updateSoPackageRetryInterface): Observable<any> {
    return this.shipmentOrderPackageDataService
      .updateShipmentOrderPackages({
        Accesstoken: updateSoPackageRetryRequest.user.accessToken,
        AccountID: updateSoPackageRetryRequest.user.accountId,
        ContactID: updateSoPackageRetryRequest.user.contactId,
        SOP: updateSoPackageRetryRequest.payload,
      })
      .pipe(
        map((resp) => {
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: updateSoPackageRetryRequest.retryCount ? updateSoPackageRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateSoPackageRetryRequest.retryInterval ? updateSoPackageRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public updateShippingFeesRetry$(updateShippingFeesRetryRequest: updateShippingFeesRetryInterface): Observable<any> {
    return this.quoteDataService
      .updateShippingFees({
        Accesstoken: updateShippingFeesRetryRequest.accessToken,
        SOID: updateShippingFeesRetryRequest.SOID,
        liabilitycoverfee: updateShippingFeesRetryRequest.liabilitycoverfee,
        shippingfee: updateShippingFeesRetryRequest.shippingfee,
      })
      .pipe(
        map((resp) => {
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }

          throw new Error('Need to retry');
        }),
        retry({
          count: updateShippingFeesRetryRequest.retryCount ? updateShippingFeesRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateShippingFeesRetryRequest.retryInterval ? updateShippingFeesRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public updateFreightAddressRetry$(updateFreightAddressRetryRequest: updateFreightAddressRetryInterface): Observable<any> {
    return this.quoteDataService
      .updateFreightAddress({
        Accesstoken: updateFreightAddressRetryRequest.accessToken,
        FreightList: [
          {
            FRID: updateFreightAddressRetryRequest.FRID,
            PickupaddressID: updateFreightAddressRetryRequest.pickupAddressId,
          },
        ],
      })
      .pipe(
        map((resp) => {
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }

          throw new Error('Need to retry');
        }),
        retry({
          count: updateFreightAddressRetryRequest.retryCount ? updateFreightAddressRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateFreightAddressRetryRequest.retryInterval ? updateFreightAddressRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public pollSoRelatedObjects$(SoRelatedObjectsPollRequest: SoRelatedObjectsInterface): Observable<ShipmentOrderRelations> {
    return this.shipmentOrderDataService
      .getShipmentOrderRelations({
        Accesstoken: SoRelatedObjectsPollRequest.accessToken,
        AccountID: SoRelatedObjectsPollRequest.accountId,
        SOID: SoRelatedObjectsPollRequest.shipmentOrderId,
      })
      .pipe(
        map((resp) => {
          if (!resp?.ShipmentOrder?.RE_TriggerEvent__c || !resp?.ShipmentOrder?.RE_CriticalError__c) {
            return resp;
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: SoRelatedObjectsPollRequest.pollCount ? SoRelatedObjectsPollRequest.pollCount : DEFAULT_POLL_COUNT,
          delay: SoRelatedObjectsPollRequest.pollInterval ? SoRelatedObjectsPollRequest.pollInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public updateFinalDeliveryAddressesRetry$(
    updateFinalDeliveryAddressesRetryRequest: updateFinalDeliveryAddressesRetryInterface
  ): Observable<any> {
    return this.quoteDataService
      .updateFinalDeliveryAddresses({
        Accesstoken: updateFinalDeliveryAddressesRetryRequest.accessToken,
        SOID: updateFinalDeliveryAddressesRetryRequest.SOID,
        Final_Delivery: updateFinalDeliveryAddressesRetryRequest.Final_Delivery,
      })
      .pipe(
        map((resp) => {
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }

          throw new Error('Need to retry');
        }),
        retry({
          count: updateFinalDeliveryAddressesRetryRequest.retryCount
            ? updateFinalDeliveryAddressesRetryRequest.retryCount
            : DEFAULT_POLL_COUNT,
          delay: updateFinalDeliveryAddressesRetryRequest.retryInterval
            ? updateFinalDeliveryAddressesRetryRequest.retryInterval
            : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public acceptQuoteRetry$(acceptQuoteRetryRequest: acceptQuoteRetryInterface): Observable<any> {
    return this.quoteDataService
      .acceptQuote({
        Accesstoken: acceptQuoteRetryRequest.accessToken,
        AccountID: acceptQuoteRetryRequest.accountId,
        SO: acceptQuoteRetryRequest.soArray,
      })
      .pipe(
        map((resp) => {
          if (resp.Success) {
            return resp;
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: acceptQuoteRetryRequest.retryCount ? acceptQuoteRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: acceptQuoteRetryRequest.retryInterval ? acceptQuoteRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public addEditLineItemsRetry$(addEditLineItemRequest: addEditLineItemsRetryInterface): Observable<Array<AddPartsResponseItem>> {
    return this.zeeQuoteDataService
      .addOrEditZeeLineItems({
        Accesstoken: addEditLineItemRequest.accessToken,
        LineItems: addEditLineItemRequest.parts,
        SOID: addEditLineItemRequest.shipmentOrderId,
      })
      .pipe(
        map((resp: AddPartsResponseItem[] | ReMessageArrayInterface) => {
          if (Array.isArray(resp)) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: addEditLineItemRequest.retryCount ? addEditLineItemRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: addEditLineItemRequest.retryInterval ? addEditLineItemRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public deleteSOPackageRetry$(deleteSoPackageRequest: deleteSoPackageRetryInterface): Observable<Success> {
    return this.shipmentOrderPackageDataService
      .deleteShipmentOrderPackages({
        Accesstoken: deleteSoPackageRequest.accessToken,
        SOP: deleteSoPackageRequest.soPackages,
      })
      .pipe(
        map((resp) => {
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: deleteSoPackageRequest.retryCount ? deleteSoPackageRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: deleteSoPackageRequest.retryInterval ? deleteSoPackageRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public updateCourierRatesRetry$(updateCourierRatesRequest: updateCourierRatesRetryInterface): Observable<Success> {
    return this.quoteDataService
      .updateCourierRates({
        Accesstoken: updateCourierRatesRequest.accessToken,
        CRID: updateCourierRatesRequest.courierRateId,
        Status: updateCourierRatesRequest.status,
      })
      .pipe(
        map((resp) => {
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: updateCourierRatesRequest.retryCount ? updateCourierRatesRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateCourierRatesRequest.retryInterval ? updateCourierRatesRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public getCourierRatesRetry$(getCourierRatesRequest: getCourierRatesRetryInterface): Observable<Array<CourierRate>> {
    return this.quoteDataService
      .getCourierRates({
        Accesstoken: getCourierRatesRequest.accessToken,
        SOID: getCourierRatesRequest.shipmentOrderId,
        FRID: getCourierRatesRequest.freightRequestId,
      })
      .pipe(
        map((resp: ReMessageArrayInterface | CourierRate[]) => {
          if (Array.isArray(resp)) {
            return resp;
          } else if (resp.Success?.Response === 'Courier rates are not available') {
            return [];
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: getCourierRatesRequest.retryCount ? getCourierRatesRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: getCourierRatesRequest.retryInterval ? getCourierRatesRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }

  public generateCostEstimatePdfRetry$(generateCostEstimateRequest: generateCostEstimateRetryInterface): Observable<Success> {
    return this.quoteDataService
      .generateCostEstimate({
        Accesstoken: generateCostEstimateRequest.accessToken,
        Identifier: 0,
        RecordID: generateCostEstimateRequest.shipmentOrderId,
      })
      .pipe(
        map((resp) => {
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: generateCostEstimateRequest.retryCount ? generateCostEstimateRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: generateCostEstimateRequest.retryInterval ? generateCostEstimateRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }
  public updateTaskRetry$(updateTaskRequest: updateTaskRetryInterface): Observable<Success> {
    let count = 0;
    return this.tasksDataService
      .updateTask({
        TaskID: updateTaskRequest.taskId,
        AccessToken: updateTaskRequest.accessToken,
        data: updateTaskRequest.taskData,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: updateTaskRequest.retryCount ? updateTaskRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateTaskRequest.retryInterval ? updateTaskRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        })
      );
  }
}
