import { Injectable, Injector } from '@angular/core';
import { DialogRef } from '../../../dialog/dialog-ref';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogShowMode } from '../../../dialog/enums/dialog-show-mode.enum';
import { AirWaybillDialogPayload } from './air-waybill-dialog-payload.interface';
import { AirWaybillDialogResult } from './air-waybill-dialog-result.interface';
import { AirWaybillDialogComponent } from './air-waybill-dialog.component';

@Injectable()
export class AirWaybillDialogService {
  constructor(
    private readonly injector: Injector,
    private readonly dialogService: DialogService
  ) {}

  public open(payload: AirWaybillDialogPayload): DialogRef<AirWaybillDialogResult> {
    return this.dialogService.open<AirWaybillDialogPayload, AirWaybillDialogResult>(AirWaybillDialogComponent, payload, {
      showMode: DialogShowMode.Side,
      closeButton: false,
      closeOnNavigation: true,
      width: '800px',
      injector: this.injector,
    });
  }
}
