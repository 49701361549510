import { Inject, Injectable } from '@angular/core';
import { ProfileDataService } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { PROFILE_PICTURE_FALLBACK } from '../tokens/profile-picture-fallback.token';

@Injectable({ providedIn: 'root' })
export class ProfilePictureService {
  constructor(
    @Inject(PROFILE_PICTURE_FALLBACK) private readonly profilePictureFallback: string,
    private readonly profileDataService: ProfileDataService
  ) {}

  public getProfilePictureWithFallback(path?: string): string {
    if (isNil(path)) {
      return this.profilePictureFallback;
    }

    const isRelativePath = path.startsWith('/');

    if (!isRelativePath) {
      return path;
    }

    const url = new URL(this.profileDataService.configuration.basePath);
    return `${url.protocol}//${url.host}${path}`;
  }
}
