import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { from, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { loadScript } from '../helpers/utils.helper';
import { TokenConfigService } from './token-config.service';

@Injectable({ providedIn: 'root' })
export class GoogleMapsApiService {
  private key;

  public googleMapsAPI$ = from(this.loadGoogleMapsAPI()).pipe(
    map(() => google.maps),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private readonly tokenConfigService: TokenConfigService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
    this.tokenConfigService.getTokens$().subscribe((tokens) => {
      this.key = tokens.googleMapsToken;
    });
  }

  private async loadGoogleMapsAPI(): Promise<void> {
    if (this.key) {
      await loadScript(`https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.key}&language=${this.locale}`);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 50));
      return this.loadGoogleMapsAPI();
    }
  }

  public getAutocomplete$(input: HTMLInputElement, addressType = 'address'): Observable<google.maps.places.Autocomplete> {
    return this.googleMapsAPI$.pipe(map((api) => new api.places.Autocomplete(input, { types: [addressType] })));
  }

  public autocompletePlaces$(autocomplete: google.maps.places.Autocomplete, country?: string): Observable<google.maps.places.PlaceResult> {
    return this.googleMapsAPI$.pipe(
      switchMap((api) => {
        const componentRestrictions = isNil(country) ? undefined : { country: [country] };
        autocomplete.setComponentRestrictions(componentRestrictions);
        return new Observable<google.maps.places.PlaceResult>((observer) => {
          const listener = api.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            observer.next(place);
          });
          return () => api.event.removeListener(listener);
        });
      })
    );
  }

  public findCountry$(country?: string): Observable<string | undefined> {
    if (isNil(country)) {
      return of(country);
    }
    return this.googleMapsAPI$.pipe(
      switchMap((api) => {
        const randomDiv = document.createElement('div');
        const placesService = new api.places.PlacesService(randomDiv);
        return new Observable<string>((observer) => {
          try {
            placesService.textSearch({ query: country, types: ['address'], type: 'country' }, (result) => {
              const placeId = get(result, [0, 'place_id']);
              if (isNil(placeId)) {
                observer.next();
                return;
              }
              placesService.getDetails({ placeId: result[0].place_id }, (details) => {
                const countryCode = get(details, ['address_components', 0]);
                observer.next(isNil(countryCode) ? undefined : details.address_components[0].short_name);
              });
            });
          } catch {
            observer.next();
          }
        });
      })
    );
  }
}
