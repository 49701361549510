import { Loadable } from '../interfaces/loadable.interface';

export class LoadableStateReducerHelper {
  public static startLoad<T>(state: Loadable<T>): Loadable<T> {
    return { ...state, isLoading: true };
  }
  public static loadSuccess<T>(data: T): Loadable<T> {
    return { data, isLoading: false };
  }
  public static loadError<T>(state: Loadable<T>, error?: string): Loadable<T> {
    return { ...state, error, isLoading: false };
  }
}
