import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { WeightUnit } from '../enums/weight-unit.enum';

@Pipe({
  name: 'weightUnit',
  pure: true,
  standalone: true,
})
export class WeightUnitPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  // eslint-disable-next-line rxjs/finnish
  public transform(value: WeightUnit): Observable<string> {
    switch (value) {
      case WeightUnit.Kg: {
        return this.translateService.get('COMMON.KG');
      }
      case WeightUnit.Lbs: {
        return this.translateService.get('COMMON.LBS');
      }
      default: {
        return of('Unknown unit');
      }
    }
  }
}
