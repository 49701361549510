import { GetUsersResponseItem } from '@tecex-api/data';
import { TeamMemberRole } from '../enums/team-member-role.enum';

// tslint:disable-next-line: cyclomatic-complexity
export const mapTeamMemberRole = (user: GetUsersResponseItem): TeamMemberRole => {
  switch (user.Role) {
    case 'Inside Sales Manager': {
      return TeamMemberRole.InsideSalesManager;
    }
    case 'Operations Manager': {
      return TeamMemberRole.OperationsManager;
    }
    case 'Account Manager':
    case 'AM': {
      return TeamMemberRole.AccountManager;
    }
    case 'Lead Account Manager':
    case 'Lead AM': {
      return TeamMemberRole.LeadAccountManager;
    }
    case 'Branch Manager': {
      return TeamMemberRole.BranchManager;
    }
    case 'Financial Controller': {
      return TeamMemberRole.FinancialController;
    }
    case 'Financial Manager': {
      return TeamMemberRole.FinancialManager;
    }
    default: {
      return TeamMemberRole.None;
    }
  }
};
