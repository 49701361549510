<div *ngIf="iconTemplate" class="icon mr-3">
  <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
</div>
<div *ngIf="iconComponent" class="icon mr-3">
  <ng-container [ngComponentOutlet]="iconComponent"></ng-container>
</div>
<div class="message">
  <ng-content></ng-content>
  <ng-template cdkPortalOutlet></ng-template>
</div>
<button *ngIf="callToActionLabel" type="button" class="cta text-small-bold ml-4" (click)="onCTAClick()">
  {{ callToActionLabel }}
</button>
<button *ngIf="closeable" mat-icon-button type="button" (click)="onCloseClick()" class="close-button pl-3">
  <svg-icon key="close"></svg-icon>
</button>
