import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { GoogleMapsApiService } from '../../services/google-maps-api.service';

@Directive({
  selector: 'input[appGoogleMapAutocomplete]',
  standalone: true,
})
export class GoogleMapAutocompleteDirective implements AfterViewInit, OnDestroy {
  @Input() public set country(country: string) {
    this.country$.next(country);
  }
  public get country(): string {
    return this.country$.getValue();
  }
  private readonly country$ = new BehaviorSubject<string | undefined>(undefined);
  private readonly countryCode$: Observable<string | undefined> = this.country$.pipe(
    distinctUntilChanged(),
    switchMap((country) => this.googleMapsApiService.findCountry$(country)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  @Output() public addressSelect = new EventEmitter<google.maps.places.PlaceResult>();

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly elementRef: ElementRef<HTMLInputElement>,
    private readonly googleMapsApiService: GoogleMapsApiService
  ) {}

  public ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getPlaceAutocomplete() {
    combineLatest([this.googleMapsApiService.getAutocomplete$(this.elementRef.nativeElement), this.countryCode$])
      .pipe(
        switchMap(([autocomplete, country]) => this.googleMapsApiService.autocompletePlaces$(autocomplete, country)),
        takeUntil(this.destroyed$)
      )
      .subscribe((place) => this.addressSelect.emit(place));
  }
}
