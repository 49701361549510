import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Observable creator that accepts list of observables
 * returns a single observable that chains all the streams into a single sequence
 *
 * Example:
 * multiple requests need to be done sequentially one after another
 */
export const sequence = <T = void>(streams: Observable<any>[]): Observable<T> =>
  streams.reduce<Observable<any>>(
    (streamChain$, stream$) => streamChain$.pipe(switchMap(() => stream$)),
    new Observable((observer) => {
      observer.next();
      observer.complete();
    })
  );
