import { ModuleWithProviders, NgModule } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { SvgIconRegistry } from '@ngneat/svg-icon';
import { SvgIconComponent } from './svg-icon.component';

@NgModule({
  imports: [SvgIconComponent],
  exports: [SvgIconComponent],
})
export class SvgIconsModule {
  public static forRoot(config: Partial<any> = {}): ModuleWithProviders<SvgIconsModule> {
    return {
      ngModule: SvgIconsModule,
      providers: [
        {
          provide: '',
          useValue: config,
        },
      ],
    };
  }

  public static forChild(icons: any): ModuleWithProviders<SvgIconsModule> {
    return {
      ngModule: SvgIconsModule,
      providers: [{ provide: '', useValue: icons, multi: true }],
    };
  }

  constructor(
    // @Optional() @Self() @Inject(SVG_ICONS) icons: any,
    // @Optional() @Inject(SVG_MISSING_ICON_FALLBACK) missingIconFallback: any,
    private readonly service: SvgIconRegistry
  ) {
    // if (icons) {
    //   this.service.register(([] as any[]).concat(...icons));
    // }
    //
    // if (missingIconFallback) {
    //   this.service.register(missingIconFallback);
    // }
  }
}
