import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { DialogModule } from '../dialog/dialog.module';
import { SessionExpiredDialogComponent } from './components/session-expired-dialog/session-expired-dialog.component';
import { SessionService } from './services/session.service';

@NgModule({
  imports: [GlobalModule, TranslateModule, MatButtonModule, DialogModule, SessionExpiredDialogComponent],
  exports: [SessionExpiredDialogComponent],
})
export class SessionModule {
  public static forRoot(): ModuleWithProviders<SessionModule> {
    return { ngModule: SessionModule, providers: [SessionService] };
  }
}
