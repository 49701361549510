import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { TrackerComponent } from './tracker.component';

@NgModule({
  imports: [GlobalModule, TranslateModule, TrackerComponent],
  exports: [TrackerComponent],
})
export class TrackerModule {}
