export enum TeamMemberRole {
  None,
  AccountManager,
  LeadAccountManager,
  InsideSalesManager,
  OperationsManager,
  BranchManager,
  FinancialController,
  FinancialManager,
}
