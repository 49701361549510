export const mapGoogleMapsAddress = (fullAddress: string) => {
  const addresses = ['', '', ''];
  const addressArray = fullAddress.split(' ');

  addressArray.forEach((word) => {
    if (
      (addresses[0].length === 0 && addresses[0].length + word.length < 35) ||
      (addresses[0].length > 0 && addresses[0].length + word.length + 1 < 35)
    ) {
      addresses[0] += addresses[0].length === 0 ? word : ` ${word}`;
    } else if (
      (addresses[1].length === 0 && addresses[1].length + word.length < 35) ||
      (addresses[1].length > 0 && addresses[1].length + word.length + 1 < 35)
    ) {
      addresses[1] += addresses[1].length === 0 ? word : ` ${word}`;
    } else {
      addresses[2] += addresses[2].length === 0 ? word : ` ${word}`;
    }
  });
  return addresses;
};
