<div class="d-flex align-items-center justify-content-between message-participants" (click)="$event.stopPropagation()">
  <div class="d-flex align-items-center">
    <img
      class="profile-picture picture mr-2"
      [src]="(teamMember.photoUrl ? teamMember.photoUrl : teamMember.FullPhotoUrl) | profilePicture"
    />
    <span *ngIf="!keyword" class="text-normal-bold-message-participant">
      {{ teamMemberName(teamMember) }} <svg-icon *ngIf="teamMember.DefaultMuteStatus" key="notification-mute" class="icon"></svg-icon>
      <span class="company-third-party-name" *ngIf="teamMember?.Role || teamMember.roleOfParticipant">
        {{ participantSection ? '(' + (teamMember.Role ? teamMember.Role : teamMember.roleOfParticipant) + ')' : null }}
      </span>
      <span class="company-third-party-name" *ngIf="teamMember?.ThirdPartyCompany">
        {{ teamMember.ThirdPartyCompany ? '(' + teamMember.ThirdPartyCompany + ')' : null }}
      </span>
    </span>
    <span *ngIf="keyword" class="text-normal-regular" [innerHTML]="searchedName"></span>
  </div>
  <div class="ml-3">
    <div *ngIf="removable; else add" matRipple class="d-flex align-items-center color-error button" (click)="onRemoveClick()">
      <svg-icon key="close"></svg-icon>
      <span class="text-normal-bold-message-participant pl-1">{{ 'COMMON.REMOVE' | translate }}</span>
    </div>
    <ng-template #add>
      <svg-icon *ngIf="added; else addUser" key="check" class="color-success icon-check mr-3"></svg-icon>
      <ng-template #addUser>
        <div matRipple class="d-flex align-items-center color-secondary button" (click)="onAddClick()">
          <svg-icon key="add"></svg-icon>
          <span class="text-normal-bold-message-participant pl-1">{{ 'COMMON.ADD' | translate }}</span>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
