<div *ngIf="user; else loading" class="d-flex align-items-center">
  <img class="profile-picture" [src]="user.profilePicture | profilePicture" alt="profile-picture" />
  <div class="pl-1">
    <div class="text-micro-regular color-t40">{{ 'MESSAGE_BUTTON.ARE_YOU_STUCK' | translate }}</div>
    <div class="d-flex text-micro-bold username" (click)="onClick()">
      <div class="">{{ 'MESSAGE_BUTTON.MESSAGE' | translate }} {{ user.name }}</div>
      <app-state-indicator-circle [color]="state.Green" class="ml-1"></app-state-indicator-circle>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex align-items-center justify-content-center">
    <app-loading-indicator></app-loading-indicator>
  </div>
</ng-template>
