import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
// @ts-ignore
import { AbstractControl } from '@ngneat/reactive-forms';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line unused-imports/no-unused-vars
export class BlockComponent<Payload, Result> {
  @Input() public payload: Payload;
  @Output() public register = new EventEmitter<AbstractControl>();
}
