import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { environment } from '@env/environment';
// import { IntercomLauncherComponent } from '@global/components/intercom-launcher/intercom-launcher.component';
import { LoginCallbackComponent } from '@global/components/login-callback/login-callback.component';
import { CONFIG_TOKEN } from '@global/config/config.token';
import { GlobalConfig } from '@global/config/global-config.interface';
import { LineItemsColumn } from '@global/enums/line-items-column.enum';
import { PackageGroupPackageContentType } from '@global/enums/package-group-package-content-type.enum';
import { SourceApp } from '@global/enums/source-app.enum';
import { GlobalModule } from '@global/global.module';
import { AuthInterceptor } from '@global/interceptors/auth.interceptor';
import { SourceAppInterceptor } from '@global/interceptors/source-app.interceptor';
import { MockInterceptor } from '@global/mock/mock.interceptor';
import { AnnouncmentModule } from '@global/modules/announcment/announcment.module';
import { AnnouncmentService, initializeAnnouncment } from '@global/modules/announcment/services/announcment.service';
import { AppNavModule } from '@global/modules/app-nav/app-nav.module';
import { CancelQuoteDialogModule } from '@global/modules/cancel-quote-dialog/cancel-quote-dialog.module';
import { CommonAddressModule } from '@global/modules/common-address/common-address.module';
import { DialogModule } from '@global/modules/dialog/dialog.module';
import { LineItemsConfigModule } from '@global/modules/line-items-config/line-items-config.module';
import { LoadingIndicatorModule } from '@global/modules/loading-indicator/loading-indicator.module';
import { PackagesModule } from '@global/modules/packages/packages.module';
import { SessionService } from '@global/modules/session/services/session.service';
import { SessionModule } from '@global/modules/session/session.module';
import { TasksModule as CommonTasksModule } from '@global/modules/tasks/tasks.module';
import { ToastMessageModule } from '@global/modules/toast-message/toast-message.module';
import { AuthService } from '@global/services/auth.service';
import { BlockScrollService } from '@global/services/block-scroll.service';
import { HotjarService } from '@global/services/hotjar.service';
import { InspectletService } from '@global/services/inspectlet.service';
// import { IntercomService } from '@global/services/intercom.service';
import { MixpanelService } from '@global/services/mixpanel.service';
import { OnboardingService } from '@global/services/onboarding.service';
import { UserpilotService } from '@global/services/userpilot.service';
import { SOURCE_APP } from '@global/tokens/source-app.token';
import { AddressModule } from '@modules/address/address.modules';
import { SelectAddressComponent } from '@modules/address/components/select-address/select-address.component';
import { CountryModule } from '@modules/country/country.module';
import { ShipmentSummaryComponent } from '@modules/tasks/components/shipment-summary/shipment-summary.component';
import { TasksModule as ZeeTasksModule } from '@modules/tasks/tasks.module';
import { profilePictureFallback } from '@shared/constants/app.constants';
import { LineItemsDataService } from '@shared/services/line-items-data.service';
import { SharedModule } from '@shared/shared.module';
import { ApiModule, CancelQuoteReasons, Configuration } from '@tecex-api/data';
// import { IntercomModule } from 'ng-intercom';
import { IHotjarSettings, NGX_HOTJAR_SETTINGS_TOKEN } from 'ngx-hotjar';
import { LottieModule } from 'ngx-lottie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { SvgIconComponent } from '@ngneat/svg-icon';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const playerFactory = () => import('lottie-web');

@NgModule(
  ///* TODO(standalone-migration): clean up removed NgModule class manually.
  {
    imports: [
      AppComponent,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      GlobalModule.forRoot({ environment, profilePictureFallback, servePort: 4201 }),
      SharedModule,
      HttpClientModule,
      CoreModule,
      ApiModule,
      AppNavModule.forRoot(),
      DialogModule.forRoot(),
      ToastMessageModule.forRoot(),
      MatMenuModule,
      LottieModule.forRoot({ player: playerFactory }),
      // NgxHotjarModule,
      AnnouncmentModule,
      // IntercomModule.forRoot({
      //     // We don't know it yet here, because config is loaded asynchronously.
      //     // But it doesn't matter, because we can override it when initializing the plugin.
      //     appId: '',
      //     updateOnRouterChange: true,
      // }),
      LoadingIndicatorModule.forRoot(),
      SessionModule.forRoot(),
      CommonAddressModule.forRoot({ hasPickupPreference: false }),
      AddressModule.forRoot(),
      ZeeTasksModule,
      CommonTasksModule.forRoot({
        selectAddressComponentType: SelectAddressComponent,
        shipmentSummaryComponentType: ShipmentSummaryComponent,
      }),
      PackagesModule.forRoot({ packageContentType: PackageGroupPackageContentType.Dangerous }),
      LineItemsConfigModule.forRoot({
        lineItemsDataServiceFactory: () => inject(LineItemsDataService),
        supportedColumns: [
          LineItemsColumn.ProductCode,
          LineItemsColumn.Description,
          LineItemsColumn.Quantity,
          LineItemsColumn.UnitPrice,
          LineItemsColumn.HsCode,
          LineItemsColumn.CountryOfOrigin,
        ],
        dynamicColumns: [LineItemsColumn.StoreFees],
        dynamicCurrencyColumns: [LineItemsColumn.UnitPrice, LineItemsColumn.StoreFees],
      }),
      CancelQuoteDialogModule.forRoot({
        cancelReasons: [
          CancelQuoteReasons.FOUND_A_BETTER_ALTERNATIVE_OPTION,
          CancelQuoteReasons.THE_COSTS_WERE_TOO_HIGH,
          CancelQuoteReasons.OTHER,
        ],
      }),
      CountryModule.forRoot(),
      FormsModule,
      // PageLayoutComponent, LoginCallbackComponent, IntercomLauncherComponent
      PageLayoutComponent,
      LoginCallbackComponent,
      SvgIconComponent,
    ],
    providers: [
      // configure API package
      {
        provide: Configuration,
        useFactory: (config: GlobalConfig, authService: AuthService) => {
          const credentials = authService.getCredentials();
          return new Configuration({
            basePath: config.apiBaseUrl,
            accessToken: credentials?.accessToken,
          });
        },
        deps: [CONFIG_TOKEN, AuthService],
      },
      { provide: SOURCE_APP, useValue: SourceApp.Zee },
      // intercepting 401 responses
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: SourceAppInterceptor,
        multi: true,
      },
      environment.debug
        ? [
            {
              provide: HTTP_INTERCEPTORS,
              useClass: MockInterceptor,
              multi: true,
            },
          ]
        : [],
      {
        provide: NGX_HOTJAR_SETTINGS_TOKEN,
        useFactory: (config: GlobalConfig): IHotjarSettings => ({
          trackingCode: config.hotjarSiteId,
        }),
        deps: [CONFIG_TOKEN],
      },
      { provide: APP_INITIALIZER, multi: true, useFactory: initializeAnnouncment(SourceApp.Zee), deps: [AnnouncmentService] },
      OnboardingService,
    ],
    // bootstrap: [AppComponent]
  }
)
export class AppModule {
  constructor(
    public readonly blockScrollService: BlockScrollService,
    private readonly sessionService: SessionService,
    private readonly inspectletService: InspectletService,
    private readonly userpilotService: UserpilotService,
    private readonly hotjarService: HotjarService,
    // private readonly intercomService: IntercomService,
    private readonly mixpanelService: MixpanelService
  ) {}
}
