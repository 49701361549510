<form hidden #fileForm>
  <input type="file" [multiple]="multiple" [accept]="accept" (change)="handleFileInputChange($event)" #fileInput />
</form>
<div class="file-input-container d-flex flex-column h-100">
  <div
    class="drag-and-drop-container d-flex align-items-center h-100"
    drop-area
    [class.highlight]="isDraggingOver"
    (click)="fileInput.click()"
    (dragenter)="dragEnter()"
    (dragleave)="dragLeave()"
    (dragover)="dragOver($event)"
    (drop)="drop($event)"
  >
    <div class="drop-area d-flex align-items-center" drop-area>
      <svg-icon key="drag-and-drop" class="color-secondary mr-3"></svg-icon>
      <div>
        <div class="drag-and-drop-text text-link md-dark">
          {{ 'FILE_INPUT.DRAG_AND_DROP' | translate }}
        </div>
        <div
          *ngIf="descriptionEnabled"
          class="text-micro-regular color-text-secondary"
          [innerHTML]="'FILE_INPUT.UPLOAD_DETAILS' | translate"
        ></div>
      </div>
    </div>
  </div>
</div>
