import { CreateShipmentOrderPackageWithSOID } from '@tecex-api/data';
import { ChargeableWeightDialogResult } from '../interfaces/chargeable-weight-dialog.vm';
import { mapDimensionsUnitPayload } from './map-dimensions-unit-payload.helper';
import { mapWeightUnitPayload } from './map-weight-unit-payload.helper';
import { roundDecimal } from './utils.helper';

export const mapCreatePackagesPayload = (
  shipmentOrderId: string,
  payload: ChargeableWeightDialogResult
): CreateShipmentOrderPackageWithSOID[] =>
  payload.packages.map((shipmentPackage) => ({
    SOID: shipmentOrderId,
    Actual_Weight: roundDecimal(shipmentPackage.weight),
    Breadth: roundDecimal(shipmentPackage.breadth),
    Height: roundDecimal(shipmentPackage.height),
    Length: roundDecimal(shipmentPackage.length),
    Packages_of_Same_Weight: shipmentPackage.packageCount,
    Dimension_Unit: mapDimensionsUnitPayload(shipmentPackage.lengthUnit),
    Weight_Unit: mapWeightUnitPayload(shipmentPackage.weightUnit),
    LithiumBatteries: shipmentPackage.hasBatteries,
    DangerousGoods: shipmentPackage.hasDangerousGoods,
  }));
