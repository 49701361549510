import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { AuthService } from '../services/auth.service';

const UNAUTHORIZED_HTTP_STATUS = 401;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    private readonly authService: AuthService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only intercept API requests
    if (!request.url.startsWith(this.config.apiBaseUrl)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === UNAUTHORIZED_HTTP_STATUS) {
          // eslint-disable-next-line no-console
          console.log('[AUTH-INTERCEPTOR] 401 response detected, logging out');
          this.authService.logout();

          return EMPTY;
        }

        return throwError(error);
      })
    );
  }
}
