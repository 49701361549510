import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MessagePayload } from '../../../interfaces/messages/message-payload.interface';
import { MessageThreadDetails } from '../../../interfaces/messages/message-thread-details.interface';
import { Participant } from '../../../interfaces/participant.interface';
import { TeamMemberLists } from '../../../interfaces/team-member-lists.interface';
import { TeamMemberListType } from '../../message-thread/enums/team-member-list-type.enum';
import { ChannelAndMessage } from '../../message-thread/services/sendbird-message.service';
import { MessageDialogPayload } from '../types/message-dialog-payload.interface';

@Injectable()
export abstract class MessageDialogDataService {
  public abstract getInitialMessageThread$(payload: MessageDialogPayload): Observable<MessageThreadDetails>;

  public abstract getMessageThread$(id: string, initial?: boolean): Observable<MessageThreadDetails>;

  public abstract getMessageThreadSendbird$(id: string, payload?: MessageDialogPayload, initial?: boolean): Observable<ChannelAndMessage>;

  public abstract pollMessageThread$(messageThread: MessageThreadDetails): Observable<MessageThreadDetails>;

  public abstract createMessageThread$(subject: string, messageThread: MessageThreadDetails): Observable<MessageThreadDetails>;

  public abstract getTeamMembers$(shipmentOrderId?: string, teamMemberListType?: TeamMemberListType): Observable<TeamMemberLists>;

  public abstract sendMessage$(
    messageThreadDetails: MessageThreadDetails,
    teamMembers: Participant[],
    message: MessagePayload
  ): Observable<void>;

  public abstract addParticipant$(messageThreadDetails: MessageThreadDetails, participant: Participant): Observable<void>;
}
