import isNil from 'lodash/isNil';
import { Radix } from '../enums/radix.enum';

export const convertTimeString = (timeString: string): [number, number] => {
  const [, hour, minutes, period] = timeString.match(/(\d+):(\d+) (AM|PM)/);

  if (isNil(hour) || isNil(minutes) || isNil(period)) {
    throw new Error(`Invalid string input: ${timeString}`);
  }

  const parsedHour = Number.parseInt(hour, Radix.Decimal);
  const parsedMinutes = Number.parseInt(minutes, Radix.Decimal);
  let offset = 0;
  if (period === 'AM') {
    // tslint:disable-next-line: no-magic-numbers
    offset = parsedHour === 12 ? -12 : 0;
  } else {
    // tslint:disable-next-line: no-magic-numbers
    offset = parsedHour === 12 ? 0 : 12;
  }
  const fullHour = parsedHour + offset;
  return [fullHour, parsedMinutes];
};
