export enum CurrencyCode {
  AED = 'AED',
  AUD = 'AUD',
  CAD = 'CAD',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  INR = 'INR',
  SGD = 'SGD',
  USD = 'USD',
  ZAR = 'ZAR',
  JPY = 'JPY',
  CNY = 'CNY',
  CZK = 'CZK',
}
