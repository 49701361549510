import { AfterViewInit, Directive, DoCheck, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTextareaAutosize]',
  standalone: true,
})
export class TextareaAutosizeDirective implements AfterViewInit, DoCheck {
  @HostBinding('style.overflow')
  public overflow = 'hidden';

  @HostBinding('style.resize')
  public resize = 'none';

  @Input()
  @HostBinding('rows')
  public rows = 1;

  constructor(
    private readonly elem: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  public ngAfterViewInit(): void {
    this.updateHeight();
  }

  public ngDoCheck(): void {
    this.updateHeight();
  }

  @HostListener('input')
  private updateHeight(): void {
    const textarea = this.elem.nativeElement as HTMLTextAreaElement;
    // Calculate border height which is not included in scrollHeight
    const borderHeight = textarea.offsetHeight - textarea.clientHeight;
    // Reset textarea height to auto that correctly calculate the new height
    this.setHeight('auto');
    // Set new height
    this.setHeight(`${textarea.scrollHeight + borderHeight}px`);
  }

  private setHeight(value: string): void {
    this.renderer.setStyle(this.elem.nativeElement, 'height', value);
  }
}
