<mat-select
  [value]="value"
  (openedChange)="onOpenedChange()"
  (selectionChange)="onSelectionChange($event)"
  [placeholder]="placeholder"
  panelClass="app-select"
>
  <mat-select-trigger class="text-small-bold color-text-primary">
    {{ getViewValue() }}
  </mat-select-trigger>
  <mat-option *ngFor="let item of data" [value]="item.value">{{ item.viewValue }}</mat-option>
</mat-select>

<svg-icon key="arrow-down" class="select-toggle-icon" (click)="onArrowClick()"></svg-icon>
