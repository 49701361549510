<div class="message-container py-4" [class.own]="message.isOwn">
  <img class="profile-picture picture" [src]="message.user?.pictureUrl | profilePicture" />
  <div class="message d-flex flex-column p-3">
    <!-- TODO: There is no way now to find out if it is a TecEx message or not -->
    <!--<img *ngIf="!message.isOwn" class="logo align-self-end" src="assets/icons/dark_logo.svg" alt="TecEx logo" width="40" height="16" /> -->
    <div class="body text-tiny-regular" [innerHTML]="body"></div>
    <div class="footer d-flex justify-content-between mt-3">
      <div class="attachments">
        <a
          *ngFor="let attachment of message.attachments; let first = first"
          class="text-reduced-bold d-flex align-items-center attachment"
          [class.mt-2]="!first"
          [href]="attachment.id | downloadDocumentUrl"
        >
          <svg-icon key="attachment" class="mr-1"></svg-icon> {{ attachment.name }}
        </a>
      </div>
      <div class="date text-micro-regular d-flex align-items-end" [class.color-text-tertiary]="!message.isOwn">
        {{ message.createdDate | messageDate }}
      </div>
    </div>
  </div>
  <div class="author px-3 text-tiny-regular color-text-tertiary">{{ message.user?.firstName }} {{ message.user?.lastName }}</div>
</div>
