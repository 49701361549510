import { WeightUnit as WeightUnitPayload, WeightUnitWithNull } from '@tecex-api/data';
import { WeightUnit } from '../enums/weight-unit.enum';

export const mapWeightUnitPayload = (weightUnit: WeightUnit): WeightUnitPayload => {
  switch (weightUnit) {
    case WeightUnit.Kg: {
      return WeightUnitPayload.KGS;
    }
    case WeightUnit.Lbs: {
      return WeightUnitPayload.LBS;
    }
    default: {
      return WeightUnitPayload.KGS;
    }
  }
};

export const mapWeightUnitWithNullPayload = (weightUnit: WeightUnit): WeightUnitWithNull => {
  switch (weightUnit) {
    case WeightUnit.Kg: {
      return WeightUnitWithNull.KGS;
    }
    case WeightUnit.Lbs: {
      return WeightUnitWithNull.LBS;
    }
    default: {
      return WeightUnitWithNull.I_CHOOSE_UPON_CREATION;
    }
  }
};
