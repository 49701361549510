import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LoadingIndicatorService } from './services/loading-indicator.service';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, LoadingIndicatorComponent],
  exports: [LoadingIndicatorComponent],
  providers: [],
})
export class LoadingIndicatorModule {
  public static forRoot(): ModuleWithProviders<LoadingIndicatorModule> {
    return { ngModule: LoadingIndicatorModule, providers: [LoadingIndicatorService] };
  }
}
