import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: 'input[formControlName][appPhoneMask] , input[formControl][appPhoneMask] , input[ngModel][appPhoneMask]',
  standalone: true,
})
export class PhoneMaskDirective implements OnInit, OnDestroy {
  @Input() public maxLength = Number.MAX_SAFE_INTEGER;

  private previousValue = '';
  private readonly destroyed$ = new Subject<void>();

  constructor(public ngControl: NgControl) {}

  public ngOnInit(): void {
    this.ngControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => this.onInputChange(value));
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onInputChange(value?: string | null): void {
    const isAboveMaxLength = value?.length > this.maxLength;
    const noLettersValue = value?.replace(/[^\d()+/\-]/g, '');
    const newValue = isAboveMaxLength ? this.previousValue : noLettersValue;
    this.previousValue = newValue;
    this.ngControl.control.setValue(newValue ? newValue : undefined, { emitEvent: false });
  }
}
