/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable unicorn/filename-case */
export class Adapter {
  public loader: any;
  public reader: any;
  public config: any;
  public file: any;
  constructor(loader: any) {
    this.loader = loader;
  }

  upload(): Promise<any> {
    return this.loader.file.then((value) => {
      const filename = value.name;
      return this.read(value, filename);
    });
  }

  read(file, filename) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', function () {
        resolve({
          filename,
          urls: {
            default: reader.result,
          },
        });
      });

      reader.addEventListener('error', function (error) {
        reject(error);
      });

      reader.addEventListener('abort', function () {
        reject();
      });
      reader.readAsDataURL(file);
    });
  }

  abort() {
    if (this.reader) {
      this.reader.abort();
    }
  }
}
