import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loading-indicator',
  template: '<mat-spinner [diameter]="diameter"></mat-spinner>',
  styles: [':host { overflow: hidden }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class LoadingIndicatorComponent {
  @Input() public diameter = 50;
}
