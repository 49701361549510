import { Injectable, Injector } from '@angular/core';
import { MessageThreadDetails } from '../../../interfaces/messages/message-thread-details.interface';
import { DialogRef } from '../../dialog/dialog-ref';
import { DialogService } from '../../dialog/dialog.service';
import { DialogShowMode } from '../../dialog/enums/dialog-show-mode.enum';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { MessageDialogPayload } from '../types/message-dialog-payload.interface';
import { CaseMessageDialogDataService } from './case-message-dialog-data.service';
import { MessageDialogDataService } from './message-dialog-data.service';

@Injectable()
export class CaseMessageDialogService {
  private readonly customInjector = Injector.create({
    providers: [
      {
        provide: MessageDialogDataService as any,
        useClass: CaseMessageDialogDataService,
      },
    ],
    parent: this.injector,
  });

  constructor(
    private readonly dialogService: DialogService,
    private readonly injector: Injector
  ) {}

  public open(payload: MessageDialogPayload): DialogRef<MessageThreadDetails> {
    return this.dialogService.open<MessageDialogPayload, MessageThreadDetails>(MessageDialogComponent, payload, {
      showMode: DialogShowMode.Side,
      injector: this.customInjector,
    });
  }
}
