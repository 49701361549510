import { DimensionWithNull, ShortDimension } from '@tecex-api/data';
import { LengthUnit } from '../enums/length-unit.enum';

export const mapDimensionsUnitPayload = (lengthUnit: LengthUnit): ShortDimension => {
  switch (lengthUnit) {
    case LengthUnit.Cm: {
      return ShortDimension.CMS;
    }
    case LengthUnit.Inch: {
      return ShortDimension.INS;
    }
    default: {
      return ShortDimension.CMS;
    }
  }
};

export const mapDimensionsUnitWithNullPayload = (lengthUnit: LengthUnit): DimensionWithNull => {
  switch (lengthUnit) {
    case LengthUnit.Cm: {
      return DimensionWithNull.CM;
    }
    case LengthUnit.Inch: {
      return DimensionWithNull.INCH;
    }
    default: {
      return DimensionWithNull.I_CHOOSE_UPON_CREATION;
    }
  }
};
