import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SendbirdChannelsList } from '@global/interfaces/messages/sendbird-channel-list.interface';
import { Store } from '@ngrx/store';
import { exhaustMap, from, map, Observable, switchMap } from 'rxjs';
import { CONFIG_TOKEN } from '../../../../global/config/config.token';
import { GlobalConfig } from '../../../../global/config/global-config.interface';
import sigV4Client from '../../../../global/helpers/signv4-helper';
import * as commonMessageReducer from '../reducers';
import { SendbirdService } from './sendbird-message.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingAWSService {
  constructor(
    private readonly http: HttpClient,
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    private readonly sendBirdMessageService: SendbirdService,
    private readonly store$: Store<commonMessageReducer.AppState>
  ) {}

  // For creating the channel with user detail :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public channelCreate(userDetail: any, orgId: string) {
    return from(this.sendBirdMessageService.getAwsTokens$()).pipe(
      switchMap((data) => {
        const authUser = sigV4Client.newClient({
          accessKey: data.credentials.AccessKeyId,
          secretKey: data.credentials.SecretAccessKey,
          sessionToken: data.credentials.SessionToken,
          region: data.region,
          endpoint: data.credentials.awsMessagingApiBaseUrl,
          serverTimeOffset: data.credentials.serverTimeOffset,
        });
        const body = userDetail;
        const method = 'POST';
        const path = `${orgId}/channelCreate`;
        const headers = { 'Content-Type': 'application/json;' };
        const response = authUser.signRequest({
          method,
          path,
          headers,
          body,
        });
        return this.http.post(response.url, body, { headers: response.headers });
      })
    );
  }

  // For updating the channel with user detail :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public updateChannelUserList(updatedUserList: any, orgId: string) {
    return from(this.sendBirdMessageService.getAwsTokens$()).pipe(
      switchMap((data) => {
        const authUser = sigV4Client.newClient({
          accessKey: data.credentials.AccessKeyId,
          secretKey: data.credentials.SecretAccessKey,
          sessionToken: data.credentials.SessionToken,
          region: data.region,
          endpoint: data.credentials.awsMessagingApiBaseUrl,
          serverTimeOffset: data.credentials.serverTimeOffset,
        });
        const method = 'put';
        const path = `${orgId}/updateChannelUser`;
        const headers = { 'Content-Type': 'application/json;' };
        const body = updatedUserList;
        const response = authUser.signRequest({
          method,
          path,
          headers,
          body,
        });
        return this.http.put(response.url, body, { headers: response.headers });
      })
    );
  }

  // For updating the channel name :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public updateChannelName(updateChannelNameDetail: any, orgId: string) {
    return from(this.sendBirdMessageService.getAwsTokens$()).pipe(
      switchMap((data) => {
        const authUser = sigV4Client.newClient({
          accessKey: data.credentials.AccessKeyId,
          secretKey: data.credentials.SecretAccessKey,
          sessionToken: data.credentials.SessionToken,
          region: data.region,
          endpoint: data.credentials.awsMessagingApiBaseUrl,
          serverTimeOffset: data.credentials.serverTimeOffset,
        });
        const method = 'patch';
        const path = `${orgId}/updateChannelName`;
        const headers = { 'Content-Type': 'application/json;' };
        const body = updateChannelNameDetail;
        const response = authUser.signRequest({
          method,
          path,
          headers,
          body,
        });
        return this.http.patch(response.url, body, { headers: response.headers });
      })
    );
  }

  // For remove the created channel :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public deleteChannel(sendbirdChannelUrl) {
    return from(this.sendBirdMessageService.getAwsTokens$()).pipe(
      exhaustMap((data) => {
        const authUser = sigV4Client.newClient({
          accessKey: data.credentials.AccessKeyId,
          secretKey: data.credentials.SecretAccessKey,
          sessionToken: data.credentials.SessionToken,
          region: data.region,
          endpoint: data.credentials.awsMessagingApiBaseUrl,
          serverTimeOffset: data.credentials.serverTimeOffset,
        });
        const method = 'post';
        const path = `angular/channelDelete`;
        const header = { 'Content-Type': 'application/json;' };
        const body = sendbirdChannelUrl;
        const response = authUser.signRequest({
          method,
          path,
          header,
          body,
        });
        return this.http.post(response.url, body, { headers: response.headers });
      })
    );
  }

  // For getting the list of channel for existing data :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getListOfChannels$(orgId: string, invoiceOrTaskId: string): Observable<SendbirdChannelsList[]> {
    return from(this.sendBirdMessageService.getAwsTokens$()).pipe(
      switchMap((data) => {
        const authUser = sigV4Client.newClient({
          accessKey: data.credentials.AccessKeyId,
          secretKey: data.credentials.SecretAccessKey,
          sessionToken: data.credentials.SessionToken,
          region: data.region,
          endpoint: data.credentials.awsMessagingApiBaseUrl,
          serverTimeOffset: data.credentials.serverTimeOffset,
        });
        const method = 'get';
        const path = `${data.orgId}/getListOfChannels`;
        const header = { 'Content-Type': 'application/json;' };
        const queryParams = {
          orgId: data.orgId,
          invoiceOrTaskId,
        };
        const response = authUser.signRequest({
          method,
          path,
          header,
          queryParams,
        });
        return this.http
          .get<SendbirdChannelsList[]>(response.url, { headers: response.headers })
          .pipe(map((channels) => channels?.filter((channel) => channel?.subject !== '-= DRAFT =-')));
      })
    );
  }
}
