import { RecordType, Task, TaskCategory, TaskState } from '@tecex-api/data';
import { MessageThreadCategory } from '../enums/message-thread-category.enum';
import { MessageThreadType } from '../enums/message-thread-type.enum';
import { MessageThread } from '../interfaces/messages/message-thread.interface';
import { doesTaskHaveMessages } from './does-task-have-messages.helper';
import { mapIsUnread, mapLastMessage } from './messages/map-message.helper';

export const mapTasksToMessageThreads = (tasks: Task[]): MessageThread[] =>
  tasks
    .filter((task) => doesTaskHaveMessages(task))
    .map((task) => ({
      id: task.Id,
      type: MessageThreadType.Task,
      category: mapCategory(task),
      subject: task.TemplateTitle,
      lastMessage: mapLastMessage(task.CreatedDate, task.LastClientMsgTime, task.LastMessageTime)?.toISOString(),
      isUnread: mapIsUnread(task.Last_Client_viewed_time, task.LastMessageTime),
      participants:
        task.Participants?.map((participant) => ({
          id: participant.Id,
          firstName: participant.Firstname,
          lastName: participant.Lastname,
        })) || [],
      shipment: task.NCPQuoteReference && {
        id: task.whatId,
        title: task.NCPQuoteReference,
        reference: task.ClientReference,
      },
    }));

const mapCategory = (task: Task): MessageThreadCategory => {
  if (task.State === TaskState.RESOLVED) {
    return MessageThreadCategory.Archived;
  }

  if (task.TaskCategory === TaskCategory.ONBOARDING) {
    return MessageThreadCategory.GenericSupport;
  }

  return task.RecordTypeName === RecordType.COST_ESTIMATE ? MessageThreadCategory.Quote : MessageThreadCategory.LiveShipment;
};
