import { enableProdMode, APP_INITIALIZER, inject, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
import { CONFIG_TOKEN } from '@global/config/config.token';
import { GlobalConfig } from '@global/config/global-config.interface';
import { playerFactory } from './app/app.module';
import { AppComponent } from './app/app.component';
import '@angular/compiler';
import { FormsModule } from '@angular/forms';
import { CountryModule } from '@modules/country/country.module';
import { CancelQuoteDialogModule } from '@global/modules/cancel-quote-dialog/cancel-quote-dialog.module';
import { LineItemsColumn } from '@global/enums/line-items-column.enum';
import { LineItemsDataService } from '@shared/services/line-items-data.service';
import { LineItemsConfigModule } from '@global/modules/line-items-config/line-items-config.module';
import { PackageGroupPackageContentType } from '@global/enums/package-group-package-content-type.enum';
import { PackagesModule } from '@global/modules/packages/packages.module';
import { ShipmentSummaryComponent } from '@modules/tasks/components/shipment-summary/shipment-summary.component';
import { SelectAddressComponent } from '@modules/address/components/select-address/select-address.component';
import { TasksModule as CommonTasksModule } from '@global/modules/tasks/tasks.module';
import { TasksModule as ZeeTasksModule } from '@modules/tasks/tasks.module';
import { AddressModule } from '@modules/address/address.modules';
import { CommonAddressModule } from '@global/modules/common-address/common-address.module';
import { SessionModule } from '@global/modules/session/session.module';
import { LoadingIndicatorModule } from '@global/modules/loading-indicator/loading-indicator.module';
// import { IntercomModule } from 'ng-intercom';
import { AnnouncmentModule } from '@global/modules/announcment/announcment.module';
import { LottieModule } from 'ngx-lottie';
import { MatMenuModule } from '@angular/material/menu';
import { ToastMessageModule } from '@global/modules/toast-message/toast-message.module';
import { DialogModule } from '@global/modules/dialog/dialog.module';
import { AppNavModule } from '@global/modules/app-nav/app-nav.module';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { profilePictureFallback } from '@shared/constants/app.constants';
import { GlobalModule } from '@global/global.module';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { OnboardingService } from '@global/services/onboarding.service';
import { initializeAnnouncment, AnnouncmentService } from '@global/modules/announcment/services/announcment.service';
import { NGX_HOTJAR_SETTINGS_TOKEN, IHotjarSettings, NgxHotjarModule } from 'ngx-hotjar';
import { MockInterceptor } from '@global/mock/mock.interceptor';
import { SourceAppInterceptor } from '@global/interceptors/source-app.interceptor';
import { AuthInterceptor } from '@global/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { SourceApp } from '@global/enums/source-app.enum';
import { SOURCE_APP } from '@global/tokens/source-app.token';
import { AuthService } from '@global/services/auth.service';
import { Configuration, ApiModule, CancelQuoteReasons } from '@tecex-api/data';
import { provideSvgIcons, provideSvgIconsConfig } from '@ngneat/svg-icon';
import { icons } from './icons';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', async (): Promise<void> => {
  const response = await fetch('assets/config/config.json');
  const config: GlobalConfig = await response.json();
  platformBrowserDynamic([
    {
      provide: CONFIG_TOKEN,
      useValue: config,
    },
  ]);

  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule,
        AppRoutingModule,
        GlobalModule.forRoot({ environment, profilePictureFallback, servePort: 4201 }),
        SharedModule,
        CoreModule,
        ApiModule,
        AppNavModule.forRoot(),
        DialogModule.forRoot(),
        ToastMessageModule.forRoot(),
        MatMenuModule,
        LottieModule.forRoot({ player: playerFactory }),
        NgxHotjarModule,
        AnnouncmentModule,
        //
        //   IntercomModule.forRoot({
        //     // We don't know it yet here, because config is loaded asynchronously.
        //     // But it doesn't matter, because we can override it when initializing the plugin.
        //     appId: '',
        //     updateOnRouterChange: true,
        // }),
        //
        LoadingIndicatorModule.forRoot(),
        SessionModule.forRoot(),
        CommonAddressModule.forRoot({ hasPickupPreference: false }),
        AddressModule.forRoot(),
        ZeeTasksModule,
        CommonTasksModule.forRoot({
          selectAddressComponentType: SelectAddressComponent,
          shipmentSummaryComponentType: ShipmentSummaryComponent,
        }),
        PackagesModule.forRoot({ packageContentType: PackageGroupPackageContentType.Dangerous }),
        LineItemsConfigModule.forRoot({
          lineItemsDataServiceFactory: () => inject(LineItemsDataService),
          supportedColumns: [
            LineItemsColumn.ProductCode,
            LineItemsColumn.Description,
            LineItemsColumn.Quantity,
            LineItemsColumn.UnitPrice,
            LineItemsColumn.HsCode,
            LineItemsColumn.CountryOfOrigin,
          ],
          dynamicColumns: [LineItemsColumn.StoreFees],
          dynamicCurrencyColumns: [LineItemsColumn.UnitPrice, LineItemsColumn.StoreFees],
        }),
        CancelQuoteDialogModule.forRoot({
          cancelReasons: [
            CancelQuoteReasons.FOUND_A_BETTER_ALTERNATIVE_OPTION,
            CancelQuoteReasons.THE_COSTS_WERE_TOO_HIGH,
            CancelQuoteReasons.OTHER,
          ],
        }),
        CountryModule.forRoot(),
        FormsModule
      ),
      // configure API package
      {
        provide: Configuration,
        useFactory: (config: GlobalConfig, authService: AuthService) => {
          const credentials = authService.getCredentials();
          return new Configuration({
            basePath: config.apiBaseUrl,
            accessToken: credentials?.accessToken,
          });
        },
        deps: [CONFIG_TOKEN, AuthService],
      },
      { provide: SOURCE_APP, useValue: SourceApp.Zee },
      // intercepting 401 responses
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: SourceAppInterceptor,
        multi: true,
      },
      environment.debug
        ? [
            {
              provide: HTTP_INTERCEPTORS,
              useClass: MockInterceptor,
              multi: true,
            },
          ]
        : [],
      {
        provide: NGX_HOTJAR_SETTINGS_TOKEN,
        useFactory: (config: GlobalConfig): IHotjarSettings => ({
          trackingCode: config.hotjarSiteId,
        }),
        deps: [CONFIG_TOKEN],
      },
      { provide: APP_INITIALIZER, multi: true, useFactory: initializeAnnouncment(SourceApp.Zee), deps: [AnnouncmentService] },
      OnboardingService,
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      provideSvgIcons(icons),
      provideSvgIconsConfig({
        defaultSize: '1em',
        icons,
      }),
    ],
  })
    // eslint-disable-next-line no-console
    .catch((error: Error): void => console.error(error));
});
