<div class="search-input d-flex">
  <svg-icon key="search" class="mr-2"></svg-icon>
  <input
    type="text"
    [placeholder]="placeholder"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    [disabled]="isDisabled"
    data-hj-allow
  />
</div>
