import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '../../dialog/dialog-ref';
import { DialogService } from '../../dialog/dialog.service';
import { DialogShowMode } from '../../dialog/enums/dialog-show-mode.enum';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { MessageDialogPayload } from '../types/message-dialog-payload.interface';
import { MessageDialogDataService } from './message-dialog-data.service';
import { TasksMessageDataDialogService } from './tasks-message-data-dialog.service';

@Injectable()
export class TasksMessageDialogService {
  private readonly customInjector = Injector.create({
    providers: [{ provide: MessageDialogDataService as any, useClass: TasksMessageDataDialogService }],
    parent: this.injector,
  });

  constructor(
    private readonly dialogService: DialogService,
    private readonly injector: Injector,
    private readonly router: Router
  ) {}

  public open(payload: MessageDialogPayload): DialogRef<void> {
    this.insertMessageToUrl();
    const dialogRef = this.dialogService.open<MessageDialogPayload, void>(MessageDialogComponent, payload, {
      showMode: DialogShowMode.Side,
      injector: this.customInjector,
    });
    dialogRef.afterClosed$().subscribe(() => this.removeMessageFromUrl());
    return dialogRef;
  }

  private insertMessageToUrl() {
    if (!window.location.hash.includes('/message')) {
      this.router.navigateByUrl(`${this.router.url}/message`, { state: { closeDialogs: false } });
    }
  }

  private removeMessageFromUrl() {
    if (window.location.hash.includes('/message')) {
      this.router.navigateByUrl(this.router.url.replace('/message', ''), { state: { closeDialogs: false } });
    }
  }
}
