<div [ngClass]="type" class="d-flex align-items-center justify-content-between message-card-container">
  <!-- ICON -->
  <div [ngClass]="type" class="d-flex align-items-center justify-content-center message-icon">
    <ng-container *ngTemplateOutlet="icon ? icon : defaultIcon"></ng-container>

    <ng-template #defaultIcon>
      <ng-container [ngSwitch]="type">
        <svg-icon key="bulb" *ngSwitchCase="'hint'"></svg-icon>
        <svg-icon key="bulb" *ngSwitchCase="'info'"></svg-icon>
        <svg-icon key="exclamation-mark" *ngSwitchDefault></svg-icon>
      </ng-container>
    </ng-template>
  </div>

  <div class="left-textbox">
    <!-- TITLE -->
    <ng-container *ngIf="title">
      <div class="text-small-bold title">
        <ng-container *ngTemplateOutlet="title"></ng-container>
      </div>
    </ng-container>

    <!-- DESCRIPTION -->
    <ng-container *ngIf="description">
      <div [ngClass]="type" class="text-micro-regular description" [class.pt-1]="title">
        <ng-container *ngTemplateOutlet="description"></ng-container>
      </div>
    </ng-container>
  </div>
  <!-- LINK -->
  <ng-container *ngIf="linkText">
    <ng-container [ngSwitch]="linkType">
      <div
        *ngSwitchCase="'default'"
        class="text-micro-bold link ml-4"
        [ngClass]="{ 'link-red': linkTextColor === 'red' }"
        (click)="messageLinkClick()"
      >
        {{ linkText }}
      </div>
      <button *ngSwitchCase="'button'" mat-button class="secondary-button" (click)="messageLinkClick()">{{ linkText }}</button>
    </ng-container>
  </ng-container>
</div>
