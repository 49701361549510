<app-form-control class="col-10 px-0 mt-40" [error]="pickupPreferenceError$ | async">
  <div class="icon-placeholder" appFormControlIcon></div>
  <span appFormControlLabel>{{ 'ADDRESS.PICKUP_PREFERENCE' | translate }}</span>
  <app-select
    [formControl]="pickupPreferenceControl"
    [data]="pickupPreferenceData"
    appFormControlInput
    class="form-control-input"
  ></app-select>
</app-form-control>
