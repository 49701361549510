<table class="table">
  <thead>
    <tr>
      <th>{{ 'TASKS.PART_SUB_CATEGORY_TABLE.PRODUCT_CODE' | translate }}</th>
      <th>{{ 'TASKS.PART_SUB_CATEGORY_TABLE.DESCRIPTION' | translate }}</th>
      <th>{{ 'TASKS.PART_SUB_CATEGORY_TABLE.PRODUCT_CATEGORY' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let lineItem of payload.lineItems; let i = index">
      <td>{{ lineItem.productCode }}</td>
      <td>{{ $any(lineItem).description }}</td>
      <td>
        <app-select
          [formControl]="$any(productCategoriesControl.controls[i])"
          [data]="productCategories$ | async"
          [placeholder]="'TASKS.PART_SUB_CATEGORY_TABLE.PICK_FROM_THE_LIST' | translate"
        ></app-select>
      </td>
    </tr>
  </tbody>
</table>
