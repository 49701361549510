import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetMaintenanceResponse, MaintenanceStatus, MiscDataService } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { asyncScheduler, BehaviorSubject, EMPTY, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, map, mapTo, observeOn, switchMap } from 'rxjs/operators';
import { SourceApp } from '../../../enums/source-app.enum';
import { ErrorNotificationService } from '../../../services/error-notification.service';
import { DialogService } from '../../dialog/dialog.service';
import { MaintenanceDialogPayload } from '../components/maintenance-dialog/maintenance-dialog-payload.interface';
import { MaintenanceDialogComponent } from '../components/maintenance-dialog/maintenance-dialog.component';
import { AnnouncmentType } from '../enums/announcment-type.enum';
import { ANNOUNCMENT_CLOSED } from '../helpers/announcment-closed-session-storage.helper';
import { Announcment } from '../types/announcment.type';

export const initializeAnnouncment = (platform: SourceApp) => (announcmentService: AnnouncmentService) => (): Promise<void> =>
  announcmentService.initialize(platform);

@Injectable({
  providedIn: 'root',
})
export class AnnouncmentService {
  public announcment$ = new BehaviorSubject<null | Announcment>(null);

  private readonly announcmentClosed;

  constructor(
    private readonly miscDataService: MiscDataService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly translateService: TranslateService,
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly dialogService: DialogService
  ) {
    this.announcmentClosed = JSON.parse(sessionStorage.getItem(ANNOUNCMENT_CLOSED));
  }

  public handleMaintenance(): void {
    this.announcment$
      .pipe(
        switchMap((announcment) => {
          if (isNil(announcment) || announcment.type === AnnouncmentType.Status) {
            return EMPTY;
          }

          // tslint:disable-next-line: no-magic-numbers
          const millisecondsUntilMaintenance = Math.floor(new Date(announcment.startDate).getTime() - Date.now());

          if (millisecondsUntilMaintenance < 0) {
            return of(announcment);
          }

          return timer(millisecondsUntilMaintenance).pipe(mapTo(announcment));
        }),
        observeOn(asyncScheduler),
        switchMap((announcment) =>
          this.dialogService
            .open<MaintenanceDialogPayload>(
              MaintenanceDialogComponent,
              { announcment },
              { closeButton: false, closeOnBackdropClick: false }
            )
            .afterClosed$()
        )
      )
      .subscribe();
  }

  public async initialize(platform: SourceApp): Promise<void> {
    try {
      const maintenance = await this.miscDataService.getMaintenance().toPromise();

      this.evaluateMaintenance(maintenance, platform);
    } catch (error) {
      this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_LOAD_ANNOUNCMENT');
    }
  }

  public evaluateMaintenance(maintenance: GetMaintenanceResponse, platform: SourceApp, clear?: boolean): Promise<void> | void {
    if (isNil(maintenance.Success?.Id)) {
      return;
    }

    if (isNil(maintenance.Success.Schedulestarttime) && isNil(maintenance.Success.ScheduleendtimeUTC)) {
      return this.announcment$.next({
        type: AnnouncmentType.Status,
        message: platform === SourceApp.TecEx ? maintenance.Success.DisplayMessage : maintenance.Success.ZeeDisplayMessage,
      });
    }
    if (
      ![MaintenanceStatus.ACTIVE, MaintenanceStatus.ON_GOING].includes(maintenance.Success?.Status) ||
      new Date(maintenance.Success.ScheduleendtimeUTC) < new Date()
    ) {
      return;
    }

    this.announcment$.next({
      type: AnnouncmentType.Maintenance,
      message: platform === SourceApp.TecEx ? maintenance.Success.DisplayMessage : maintenance.Success.ZeeDisplayMessage,
      startDate: maintenance.Success.Schedulestarttime,
      endDate: maintenance.Success.ScheduleendtimeUTC,
      status: maintenance.Success.Status,
    });
  }

  public isUnderMaintenance$(): Observable<boolean> {
    return this.announcment$.pipe(
      map((announcment) => {
        if (isNil(announcment)) {
          return false;
        }

        return announcment.type === AnnouncmentType.Maintenance && announcment.status === MaintenanceStatus.ON_GOING;
      })
    );
  }

  // tslint:disable: no-magic-numbers no-null-keyword
  public getMessage$(): Observable<null | string> {
    return this.announcment$.pipe(
      switchMap((announcment) => {
        if (this.announcmentClosed) {
          return of(null);
        }

        if (isNil(announcment)) {
          return of(null);
        }

        if (announcment.type === AnnouncmentType.Status) {
          return of(announcment.message);
        }

        return timer(0, 1000).pipe(
          map(() => {
            const differenceInSeconds = Math.floor((new Date(announcment.startDate).getTime() - Date.now()) / 1000);

            if (differenceInSeconds < 0) {
              return null;
            }

            const remainingMinutes = Math.floor(differenceInSeconds / 60);
            const remainingSeconds = differenceInSeconds % 60;

            const date = formatDate(announcment.startDate, 'dd LLL, yyyy', this.locale);
            const from = formatDate(announcment.startDate, 'hh:mm aaa', this.locale);
            const to = formatDate(announcment.endDate, 'hh:mm aaa', this.locale);
            const fromTo = this.translateService.instant('MAINTENANCE.FROM_TO', { from, to });

            const message = `${announcment.message} ${date} ${fromTo}.`;

            if (remainingMinutes > 60) {
              return message;
            }

            const countdown = `${remainingMinutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
            const translatedCountdown = this.translateService.instant('MAINTENANCE.COUNTDOWN', {
              countdown,
            });

            return `${message} ${translatedCountdown}`;
          })
        );
      }),
      distinctUntilChanged()
    );
  }

  public closeAnnouncment(): void {
    sessionStorage.setItem(ANNOUNCMENT_CLOSED, JSON.stringify(true));
    this.announcment$.next(null);
  }
}
