import { FeedsonCaseItem, UsersDetailsItem } from '@tecex-api/data';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { MessageThreadMessage } from '../../interfaces/messages/message-thread-message.interface';

export const mapMessages = (messages: FeedsonCaseItem[], users: UsersDetailsItem[], userId: string): MessageThreadMessage[] =>
  isNil(messages)
    ? []
    : [...messages]
        .reverse()
        .filter((message) => !isNil(message.Body) || !isEmpty(message.ContentDocId))
        .map((message) => {
          const user = users.find((item) => item.Id === message.InsertedById);

          return {
            id: message.Id,
            body: message.Body || '',
            attachments:
              message.ContentDocId?.map((document) => ({
                id: document.ContentdocId,
                name: document.Title,
              })) || [],
            createdDate: new Date(message.createddate),
            user: user && {
              id: user.Id,
              firstName: user.FirstName,
              lastName: user.LastName,
              pictureUrl: user.FullPhotoUrl,
            },
            isOwn: user?.Id === userId,
            isReply: isNil(message.CommentCount),
          };
        });
